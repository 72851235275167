import React, {useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import { Grid, Input, InputBase } from '@mui/material';
import Chip from '@mui/material/Chip';
import Loader from '../../../../../components/atoms/loader';
import styles from "./styles.module.scss";
import ListItemText from '@mui/material/ListItemText';

const InfiniteSelect = ({label,setPage,page, disable, data, handleNextData, handleMenuItemClick}: any) => {
    const [open, setOpen] = useState(false);
    const dropdownRef: any = React.useRef(null);
    // const [names, setNames] = useState<any>(data || ['sam1', 'sam1', 'sam1', 'sam1', 'sam1', 'sam1', 'sam1', 'sam1', 'sam1']);

    const handleClickOpen = (e: any) => {
        e?.stopPropagation()
        setOpen(!open)
        setPage(0)
    }

  return (
    <div style={{ position: 'relative', pointerEvents: disable ? 'none' : 'all', opacity: disable ? 0.5 : 1, width: '100%' }}>
      <div style={{ padding: '6px 8px 8px 18px', cursor: 'pointer', border: '1px solid rgb(95, 70, 254)', borderRadius: '8px', height: '36px', width: 220 }} onClick={(e) => handleClickOpen(e)}>
        <InputLabel style={{ position: 'relative', display: 'flex', cursor: 'pointer', justifyContent: 'space-between', fontSize: '14px', fontWeight: 400, color: 'rgb(95, 70, 254)', alignItems: 'center' }}>
          {label ? label : <div></div>} {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </InputLabel>
      </div>
      {open && (
        <div style={{ position: 'absolute', zIndex: 2, top: '100%', left: 0, width: '100%' }}>
          <Grid ref={dropdownRef} sx={{
            width: 220, padding: '0px', borderRadius: '4px', overflow: 'hidden', position: 'relative',
            maxHeight: '300px',
            boxShadow: 0,
            fontFamily: 'Poppins',
            border: '1px solid #EAECF0',
            background: 'white'
          }}>
            {/* <Grid>
              <InputBase
                placeholder='Type here to search'
                onChange={handleSearch}
                value={search}
                sx={{
                  width: '100%',
                  padding: '4px',
                  paddingTop: '0px',
                  // paddingLeft: '4px',
                  borderBottom: '1px solid #EAECF0',
                  '& .MuiInputBase-input::placeholder': {
                    color: 'rgb(200, 197, 208)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.5px'
                  }
                }}
              />
            </Grid> */}
            <Grid id="scrollableDiv" className={styles.dropdownList} style={{ padding: '0px' }}>
              <InfiniteScroll
                dataLength={data?.length} //This is important field to render the next data
                next={handleNextData}
                hasMore={true}
                scrollableTarget="scrollableDiv"
                loader={<div style={{ height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Loader size={25} />
                </div>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                onScroll={(e) => console.log('Scrolled', e)}
              >
                {data?.map((item: any, index: any) => (
                  <MenuItem
                    key={item?.label}
                    value={item?.label}
                    onClick={() => handleMenuItemClick(item?.label)}

                    sx={{
                      borderBottom: '1px solid #EAECF0',
                    }}
                  >
                    <ListItemText title={item?.label} primary={item?.label} style={{ padding: '0px', fontFamily: 'Poppins', width: '220px' }}
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: 'Poppins',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }
                      }}
                    />
                  </MenuItem>
                ))}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default InfiniteSelect