import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import styles from './monetise.module.scss'
import RightTabs from '../../../components/molecules/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { onsubTabChange } from '../../../store/header/reducer';
import { SearchInput } from '../../../components/atoms/search';
import { ReactComponent as TrendingUpIcon } from '../../../assets/svg/trendingup.svg';
import CardContainer from './components/CardContainer';
import sale1 from '../../../assets/svg/sale.svg';
import sale2 from '../../../assets/svg/sale2.svg'
import sale3 from '../../../assets/svg/sale3.svg'
import sale4 from '../../../assets/svg/sale4.svg'
import sale5 from '../../../assets/svg/sale5.svg'
import { BarchartLoyality } from './components/charts/LoyalityChart';
import { BarchartCustomerRelationship } from './components/charts/CustomerChart';
import { BarchartPromotions } from './components/charts/PromotionChart';
import OrgChart from './components/charts/OrgChart';
import MapChart from './components/charts/MapChart';
import { useNavigate } from 'react-router-dom';
import BestSellersTable from './elements/BestSellers';
import RightSideDiv from './elements/RightSideDiv';
import { useDashboard } from './useDashboard';
import Loader from '../../../components/atoms/loader';
import { getAutoStack } from '../../../services/insights';
import { SideLayout } from '../../../layout/sideLayout';
import { DashboardLayout } from '../../../layout/dashboardLaylout';

export const InsightsRoot = () => {
    const subTab = useSelector((state: any) => state?.headerContext?.subHeader);
    const mainTab = useSelector((state: any) => state?.headerContext?.mainHeader);
    const {
        numericals,
        loader,
        selectBrand,
        setSelectBrand,
        brands,
        customerBrands,
        treeData,
        allTimes,
        timeLines,
        loyalityData,
        customerSwitchData,
        promotionsData,
        bestSellers,
        cardsLoader,
        chartLoader,
        loyaltyLoader,
        customerLoader,
        promotionsLoader,
        bestsellersLoader,
        mapsLoader,
        setAllTimes,
        mapsData,
        segmentationData,
        segmentationLoader
    } = useDashboard();

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [listSelTab, setListSelTab] = useState("1");
    const [expand, setExpand] = useState({
        salesTree: false,
        salesMap: false,
        loyaltyChart: false,
        customerSwitch: false,
        promotions: false
    });
    const handleType = (item: any, portal: string) => {
        navigate(item?.value);
        dispatch(onsubTabChange(item));
    }
    const tabdata = [
        { name: 'Best Sellers', id: 0, value: '/insights/best-sellers' },
        { name: 'Customer Behaviour', id: 1, value: '/insights/customer-behaviour' },
        { name: 'Segmentation', id: 2, value: '/insights/segmentation' },
    ];

    return (
        <div className={styles.insightsContainer}>
            <SideLayout />
            <div className={styles.headerContainer}>
                <RightTabs
                    {...{
                        tabs: tabdata,
                        selectedTab: subTab,
                        handleType: handleType,
                        styles: {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiToggleButton-root': {
                                fontWeight: 500,
                                textTransform: 'none',
                                fontSize: '16px',
                                borderRadius: '0px',
                                border: 'none',
                                fontFamily: 'Poppins',
                                color: 'rgb(94, 92, 113)',
                                padding: '10px 0px',
                                lineHeight: '24px',
                                textAlign: 'left',
                                '&:hover': {
                                    backgroundColor: '#FFF',
                                },
                            },
                            '& .Mui-selected.MuiToggleButton-root': {
                                padding: '12px 0px 10px 0px',
                                backgroundColor: '#FFF',
                                border: 'none',
                                boxShadow: 'none',
                                borderRadius: 'none',
                                color: 'rgb(95, 70, 255)',
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '24px',
                                borderBottom: '2px solid rgb(95, 70, 255)',
                                '&:hover': {
                                    backgroundColor: '#FFF',
                                },
                            },
                        }
                    }
                    }
                />
            </div>
            <div style={{ padding: '16px', paddingTop: "16px" }}>
                <div style={{ width: '265px', marginBottom: '16px' }}><SearchInput /></div>
                <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }} className={styles.container}>
                    <div className={styles.mainDiv} style={{
                        display: (expand?.loyaltyChart || expand?.customerSwitch || expand?.promotions) ? 'none' : ''
                    }}>
                        <div className={styles.leftsideDiv} style={{
                        }}>
                            {!cardsLoader ? (
                                <>
                                    <div className={styles.smallCards}>
                                        {numericals.slice(0, 2)?.map((card: any, index: number) => (
                                            <div className={styles.smallCard} key={index}>
                                                <div style={{ display: "flex", flexDirection: "column", gap: '12px' }}>
                                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '10px', lineHeight: '12px', color: '#202224' }}>{card?.title}</Typography>
                                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '18px', lineHeight: '22px', color: '#202224' }}>{card?.content}</Typography>
                                                    {card?.footer}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.smallCards}>
                                        {numericals.slice(2, 4)?.map((card: any, index: number) => (
                                            <div className={styles.smallCard} key={index}>
                                                <div style={{ display: "flex", flexDirection: "column", gap: '12px' }}>
                                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '10px', lineHeight: '12px', color: '#202224' }}>{card?.title}</Typography>
                                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '18px', lineHeight: '22px', color: '#202224' }}>{card?.content}</Typography>
                                                    {card?.footer}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Loader size={40} />
                                </div>
                            )}
                            <BestSellersTable data={bestSellers} bestsellersLoader={bestsellersLoader} allTimes={allTimes} setAllTimes={setAllTimes} />
                        </div>
                        <div className={`${expand.salesTree ? styles['expand-salestree'] : ''}`} style={{ display: !expand.salesTree ? 'none' : '' }} >
                            <div className={styles['orgchart-expansion']}>
                                <CardContainer
                                    {...{
                                        title: 'Sales diagnostic tree',
                                        onClick: () => setExpand({ ...expand, salesTree: !expand.salesTree }),
                                        sx: {
                                            height: 'max-content'
                                        },
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'chart',
                                        dropdown: true,
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!chartLoader ? <OrgChart
                                        {...{
                                            style: {
                                                height: '495px'
                                            },
                                            translate: {
                                                x: 20,
                                                y: 250
                                            },
                                            treeData
                                        }}
                                    /> : <div style={{ height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                            </div>
                            <div className={styles['salestree-second-expand']} style={{}}>
                                <CardContainer
                                    {...{
                                        title: 'Regional sales',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'map',
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!mapsLoader ? <MapChart
                                        {...{
                                            style: {
                                                width: "100%"
                                            },
                                            data: mapsData
                                        }}
                                    /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Segmentation',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'segmentation',
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    <div className={styles['segmentation-cards']}>
                                        {!segmentationLoader ? <>
                                            {segmentationData?.map((card: any, index: number) => (
                                                <div className={styles['segment-card']} key={index}>
                                                    <p className={styles['title']}>{card?.title}</p>
                                                    <div className={styles["tiles"]}>
                                                        {card?.segment?.map((seg: any, ind: number) => (
                                                            <div style={{ display: 'flex', gap: '60px', alignItems: 'center' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                                                    <p style={{ fontFamily: 'Inter', fontSize: '10px', fontWeight: 400, lineHeight: '20px', color: '#130F35' }}>{seg?.title}</p>
                                                                    <p style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 400, lineHeight: '20px', color: '#000000' }}>{seg?.content}</p>
                                                                </div>
                                                                <div style={{ height: '25px', width: '1px', background: '#E5E1EC', display: ind === card?.segment?.length - 1 ? 'none' : '' }} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Loader size={40} />
                                        </div>}
                                    </div>
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Loyalty',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'loyalty',
                                        options: brands,
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!loyaltyLoader ? <div style={{}}>
                                        <BarchartLoyality data={loyalityData} />
                                    </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Customer switch',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'customer',
                                        options: customerBrands,
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!customerLoader ? <div>
                                        <BarchartCustomerRelationship data={customerSwitchData} />
                                    </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Promotions',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'promotions',
                                        options: brands,
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!promotionsLoader ? <div>
                                        <BarchartPromotions data={promotionsData} />
                                    </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                            </div>
                        </div>
                        <div className={`${expand.salesMap ? styles['expand-salestree'] : ''}`} style={{ display: !expand.salesMap ? 'none' : '' }}>
                            <div className={styles['map-expansion']}>
                                <CardContainer
                                    {...{
                                        title: 'Regional sales',
                                        dropdown: true,
                                        onClick: () => setExpand({ ...expand, salesMap: !expand.salesMap }),
                                        sx: {
                                            height: 'max-content'
                                        },
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'map',
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!mapsLoader ? <MapChart
                                        {...{
                                            style: {
                                                height: '495px',
                                                width: 'calc(100% - 10px)'
                                            },
                                            data: mapsData
                                        }}
                                    /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                            </div>
                            <div className={styles['salestree-second-expand']} style={{}}>
                                <CardContainer
                                    {...{
                                        title: 'Sales diagnostic tree',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'chart',
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!chartLoader ? <OrgChart
                                        {...{
                                            style: {
                                                height: "300px"
                                            },
                                            treeData
                                        }}
                                    /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Segmentation',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'segmentation',
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    <div className={styles['segmentation-cards']}>
                                        {!segmentationLoader ? <>
                                            {segmentationData?.map((card: any, index: number) => (
                                                <div className={styles['segment-card']} key={index}>
                                                    <p className={styles['title']}>{card?.title}</p>
                                                    <div className={styles["tiles"]}>
                                                        {card?.segment?.map((seg: any, ind: number) => (
                                                            <div style={{ display: 'flex', gap: '60px', alignItems: 'center' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                                                    <p style={{ fontFamily: 'Inter', fontSize: '10px', fontWeight: 400, lineHeight: '20px', color: '#130F35' }}>{seg?.title}</p>
                                                                    <p style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 400, lineHeight: '20px', color: '#000000' }}>{seg?.content}</p>
                                                                </div>
                                                                <div style={{ height: '25px', width: '1px', background: '#E5E1EC', display: ind === card?.segment?.length - 1 ? 'none' : '' }} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Loader size={40} />
                                        </div>}
                                    </div>
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Loyalty',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'loyalty',
                                        options: brands,
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!loyaltyLoader ? <div style={{}}>
                                        <BarchartLoyality data={loyalityData} />
                                    </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Customer switch',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'customer',
                                        options: customerBrands,
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!customerLoader ? <div>
                                        <BarchartCustomerRelationship data={customerSwitchData} />
                                    </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                                <CardContainer
                                    {...{
                                        title: 'Promotions',
                                        dropdown: true,
                                        selectBrand: selectBrand,
                                        setSelectBrand,
                                        name: 'promotions',
                                        allTimes,
                                        setAllTimes
                                    }}
                                >
                                    {!promotionsLoader ? <div>
                                        <BarchartPromotions data={promotionsData} />
                                    </div> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </CardContainer>
                            </div>
                        </div>
                        <RightSideDiv
                            {...{
                                expand,
                                setExpand,
                                segmentationData,
                                selectBrand,
                                setSelectBrand,
                                brands,
                                customerBrands,
                                treeData,
                                loyalityData,
                                customerSwitchData,
                                promotionsData,
                                chartLoader,
                                loyaltyLoader,
                                customerLoader,
                                promotionsLoader,
                                bestsellersLoader,
                                segmentationLoader,
                                allTimes,
                                setAllTimes,
                                mapsLoader,
                                mapsData
                            }}
                        />
                    </div>
                    <div style={{
                        display: !(expand?.loyaltyChart || expand?.customerSwitch || expand?.promotions) ? 'none' : 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        justifyContent: 'center'
                    }}>
                        <div className={styles['remaining-charts-exapnd']}>
                            <div className={styles.smallCards}>
                                {!cardsLoader ? <>
                                    {numericals?.map((card: any, index: number) => (
                                        <div className={styles.smallCard} key={index}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: '12px' }}>
                                                <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '10px', lineHeight: '12px', color: '#202224' }}>{card?.title}</Typography>
                                                <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '18px', lineHeight: '22px', color: '#202224' }}>{card?.content}</Typography>
                                                {card?.footer}
                                            </div>
                                        </div>
                                    ))}
                                </> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Loader size={40} />
                                </div>}
                            </div>
                            <BestSellersTable
                                {...{
                                    data: bestSellers,
                                    height: '300px',
                                    bestsellersLoader,
                                    allTimes,
                                    setAllTimes
                                }}
                            />
                            <CardContainer
                                {...{
                                    title: 'Sales diagnostic tree',
                                    dropdown: true,
                                    sx: {
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'chart',
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                {!chartLoader ? <OrgChart
                                    {...{
                                        style: {
                                            height: '200px'
                                        },
                                        treeData
                                    }}
                                /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Loader size={40} />
                                </div>}
                            </CardContainer>
                            <CardContainer
                                {...{
                                    title: 'Regional sales',
                                    dropdown: true,
                                    sx: {
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'map',
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                {!mapsLoader ? <MapChart
                                    {...{
                                        style: {
                                            height: '210px',
                                            width: '100%'
                                        },
                                        data: mapsData
                                    }}
                                /> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Loader size={40} />
                                </div>}
                            </CardContainer>
                            <CardContainer
                                {...{
                                    title: 'Segmentation',
                                    dropdown: true,
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'segmentation',
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                <div className={styles['segmentation-cards']}>
                                    {!segmentationLoader ? <>
                                        {segmentationData?.map((card: any, index: number) => (
                                            <div className={styles['segment-card']} key={index}>
                                                <p className={styles['title']}>{card?.title}</p>
                                                <div className={styles["tiles"]}>
                                                    {card?.segment?.map((seg: any, ind: number) => (
                                                        <div style={{ display: 'flex', gap: '60px', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                                                <p style={{ fontFamily: 'Inter', fontSize: '10px', fontWeight: 400, lineHeight: '20px', color: '#130F35' }}>{seg?.title}</p>
                                                                <p style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 400, lineHeight: '20px', color: '#000000' }}>{seg?.content}</p>
                                                            </div>
                                                            <div style={{ height: '25px', width: '1px', background: '#E5E1EC', display: ind === card?.segment?.length - 1 ? 'none' : '' }} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </> : <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader size={40} />
                                    </div>}
                                </div>
                            </CardContainer>
                            <CardContainer
                                {...{
                                    title: 'Loyalty',
                                    dropdown: true,
                                    sx: {
                                        height: 'max-content',
                                        display: expand.loyaltyChart ? 'none' : ''
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'loyalty',
                                    options: brands,
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                <div style={{}}>
                                    <BarchartLoyality data={loyalityData} />
                                </div>
                            </CardContainer>
                            <CardContainer
                                {...{
                                    title: 'Customer switch',
                                    dropdown: true,
                                    sx: {
                                        height: 'max-content',
                                        display: expand.customerSwitch ? 'none' : ''
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'customer',
                                    options: customerBrands,
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                <div>
                                    <BarchartCustomerRelationship data={customerSwitchData} />
                                </div>
                            </CardContainer>
                            <CardContainer
                                {...{
                                    title: 'Promotions',
                                    dropdown: true,
                                    sx: {
                                        height: 'max-content',
                                        display: expand.promotions ? 'none' : ''
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'promotions',
                                    options: brands,
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                <div>
                                    <BarchartPromotions data={promotionsData} />
                                </div>
                            </CardContainer>
                        </div>
                        <div>
                            <CardContainer
                                {...{
                                    title: 'Loyalty',
                                    dropdown: true,
                                    onClick: () => setExpand({ ...expand, loyaltyChart: !expand.loyaltyChart }),
                                    sx: {
                                        height: 'max-content',
                                        display: !expand.loyaltyChart ? 'none' : ''
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'loyalty',
                                    options: brands,
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                <div style={{}}>
                                    <BarchartLoyality data={loyalityData} loader={loyaltyLoader} />
                                </div>
                            </CardContainer>
                            <CardContainer
                                {...{
                                    title: 'Customer switch',
                                    dropdown: true,
                                    onClick: () => setExpand({ ...expand, customerSwitch: !expand.customerSwitch }),
                                    sx: {
                                        height: 'max-content',
                                        display: !expand.customerSwitch ? 'none' : ''
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'customer',
                                    options: customerBrands,
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                <div>
                                    <BarchartCustomerRelationship data={customerSwitchData} loader={customerLoader} />
                                </div>
                            </CardContainer>
                            <CardContainer
                                {...{
                                    title: 'Promotions',
                                    dropdown: true,
                                    onClick: () => setExpand({ ...expand, promotions: !expand.promotions }),
                                    sx: {
                                        height: 'max-content',
                                        display: !expand.promotions ? 'none' : ''
                                    },
                                    selectBrand: selectBrand,
                                    setSelectBrand,
                                    name: 'promotions',
                                    options: brands,
                                    allTimes,
                                    setAllTimes
                                }}
                            >
                                <div>
                                    <BarchartPromotions data={promotionsData} loader={promotionsLoader} />
                                </div>
                            </CardContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}