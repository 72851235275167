import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import amazon from '../../../assets/images/amazon2.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../components/atoms/loader';

const Connections = () => {
  const navigate = useNavigate()
  const [connectionsData, setConnectedData] = useState([])
  const [loading, setLoading] = useState(false)
  React.useEffect(() => {
    const fetchMarketplaces = async () => {
      try {
        const token = localStorage.getItem('access_token');  // Retrieve token from localStorage
        if (!token) {
          throw new Error('No token found');
        }
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/amazon/connected-marketplaces`, {
          headers: {
            'Authorization': `Bearer ${token}`  // Pass token as Bearer token
          }
        });

        const finalData = response?.data?.marketplaces.map((item: any) => {
          const connectedDate = new Date(item?.connected_at);
          const formattedDate = connectedDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          });

          return {
            id: item?.marketplace_id,
            url: item?.url.replace('https://sellercentral.', ''),
            connectedOn: formattedDate,
            alert: false,
          }
        });
        setLoading(false)
        setConnectedData(finalData);
      } catch (err: any) {
        setLoading(false)
      } finally {
        setLoading(false);
      }
    };

    fetchMarketplaces();
  }, []);

  return (
    <Box sx={{ padding: 5, fontFamily: 'Poppins, sans-serif',maxHeight:'100vh',overflow:'auto',marginTop:{xs:'40px',sm:'0px'}  }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          marginBottom: 3,
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#242533',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '2rem',
            fontWeight: 600,
          }}
        >
          Manage Connections
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'white !important',
            color: '#4A3AFF',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.875rem',
            textTransform: 'none',
            boxShadow: 'none !important',
            border: '1px solid lightgrey'
          }}
          onClick={() => {
            // localStorage.setItem('is_onboarded', 'false');
            navigate(`/onboard?conn=${true}`)
          }}
        >
          + Add
        </Button>
      </Box>

      <Card
        sx={{
          padding: 2,
          fontFamily: 'Poppins, sans-serif',
          border: '1px solid #E0E0E0',
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
          borderRadius: 2,
          marginBottom:'100px'
        }}
      >
        {!loading ? <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table sx={{ minWidth: 650 }} aria-label="connections table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Connected On</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Alerts</TableCell>
                {/* <TableCell sx={{ width: '10%' }}></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {connectionsData.map((connection: any, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '40%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          // padding: '8px',
                          // backgroundColor: '#1A1A3F',
                          borderRadius: '8px',
                          // height: '32px',
                          // width: '32px',
                        }}
                      >
                        <img
                          src={amazon}
                          alt="Amazon"
                          style={{ width: '50px', height: '35px' }}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: '1rem',
                            fontWeight: 600,
                            color: '#FFFFFF',
                            backgroundColor: '#2D0D8A',
                            padding: '4px 16px',
                            borderRadius: '8px',
                            minWidth: '150px'
                          }}
                        >
                          {connection.id}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            gap: 1,
                            backgroundColor: '#E1D8FC',
                            borderRadius: '8px',
                            padding: '4px 8px',
                            minWidth: '150px'
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: 'Poppins, sans-serif',
                              fontSize: '0.875rem',
                              color: '#4A3AFF',
                              width: '100%'
                            }}
                          >
                            {connection.url}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '1rem',
                        color: '#555555',
                      }}
                    >
                      {connection.connectedOn}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {connection.alert ? (
                      <NotificationsIcon sx={{ color: 'red' }} />
                    ) : (
                      <Typography variant="body2" sx={{ color: 'green' }}>
                        No Alerts
                      </Typography>
                    )}
                  </TableCell>
                  {/* <TableCell align="right">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#242533',
                        color: '#FFFFFF',
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '0.875rem',
                        textTransform: 'none',
                        padding: '6px 16px',
                      }}
                    >
                      Revoke
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><Loader /> </div>}
        {connectionsData.length === 0 && "No Connections Found"}
      </Card>
    </Box>
  );
};

export default Connections;
