
import styles from './monetise.module.scss'
import { DashboardLayout } from '../../../layout/dashboardLaylout';
import { DailsySales } from './components/charts/dailySales';
import CardContainer from './components/CardContainer';
import { useDashboard } from './useDashboard';
import { Typography } from '@mui/material';
import PyramidChart from './components/pyramidChart';

export const NewInsightsRoot = () => {
    const {
        numericals,
    } = useDashboard();
    return (
        <div className={styles.insightsContainer} style={{ height: "calc(100vh - 90px)", overflow: 'auto', padding: '10px 0px' }}>
            <DashboardLayout />
            <div style={{ display: 'flex', gap: '20px' }}>
                <div style={{ width: '100%' }} className={styles.customWidth}>
                    <CardContainer
                        {...{
                            title: 'Daily Sales (Last 30days)',
                            // onClick: () => setExpand({ ...expand, salesTree: !expand.salesTree }),
                            sx: {
                                height: 'max-content'
                            },
                            // selectBrand: selectBrand,
                            // setSelectBrand,
                            name: 'chart',
                            dropdown: false,
                            // allTimes,
                            // setAllTimes
                        }}
                    >
                        <DailsySales />
                        <div>
                        </div>
                    </CardContainer>
                    <div style={{ marginTop: '20px' }}>
                        <CardContainer
                            {...{
                                title: '',
                                // onClick: () => setExpand({ ...expand, salesTree: !expand.salesTree }),
                                sx: {
                                    height: 'max-content'
                                },
                                // selectBrand: selectBrand,
                                // setSelectBrand,
                                name: 'chart',
                                dropdown: false,
                                // allTimes,
                                // setAllTimes
                            }}
                        >
                            <PyramidChart />
                            <div>
                            </div>
                        </CardContainer>
                    </div>
                </div>
                <div className={styles.customWidthCards} style={{ width: '15%', display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'center', background: 'white', height: "calc(100vh - 50px)" }}>
                    <h2 style={{ borderBottom: '1px solid #CDC6FF', width: '100%', padding: '9px 16px', fontSize: '16px' }}>KPIs</h2>
                    <div className={styles.newSmallCards} style={{ display: 'flex', flexDirection: 'column' }}>
                        {numericals.slice(0, 2)?.map((card: any, index: number) => (
                            <div className={styles.newSmallCard} key={index}>
                                <div style={{ display: "flex", flexDirection: "column", gap: '12px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '10px', lineHeight: '12px', color: '#202224' }}>{card?.title}</Typography>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '18px', lineHeight: '22px', color: '#202224' }}>{card?.content}</Typography>
                                    {card?.footer}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.newSmallCards} style={{ display: 'flex', flexDirection: 'column' }}>
                        {numericals.slice(2, 4)?.map((card: any, index: number) => (
                            <div className={styles.newSmallCard} key={index}>
                                <div style={{ display: "flex", flexDirection: "column", gap: '12px' }}>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '10px', lineHeight: '12px', color: '#202224' }}>{card?.title}</Typography>
                                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '18px', lineHeight: '22px', color: '#202224' }}>{card?.content}</Typography>
                                    {card?.footer}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}