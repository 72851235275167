import { useEffect, useState } from 'react';
import { BarchartPromotions } from './barchart';
import styles from '../styles.module.scss';
import Select from '../../../../../../components/atoms/Select';
import { menustyles, selectStyles } from '../customer-switch';
import { getPromotionsData } from '../../../../../../services/insights';
import Loader from '../../../../../../components/atoms/loader';
import InfiniteSelect from '../../../elements/InfiniteSelect';

export const Promotions = ({brands, skus, filter, page, setPage}: any) => {
    const [brand, setBrand] = useState(brands[0]?.value);
    const [sku, setSku] = useState(skus[0]?.value);
    const [loader, setLoader] = useState(false);
    const [promotionsData, setPromotionsData] = useState<any>({
        dateLabels: [],
        promos: [],
        nonPromos: []
    });

    const handleChange = (e: any, type: string) => {
        if(type==='brands'){
            setBrand(e.target.value);
        }else if(type==='skus'){
            setSku(e.target.value);
        }
    }

    const handlePromoBrandChange = (e: any) => {
        setBrand(e)
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const formatDate = (dateStr:string) => {
        const [year, monthIndex] = dateStr?.split('-');
        const month = months[parseInt(monthIndex) - 1];
        return `${month} ${year}`;
    };

    const getPromotionsChartData = async (item?:string) => {
        try {
            setLoader(true)
            const data: any = await getPromotionsData({ 
                products: [item], 
                timeline: [filter?.timeline]
            });
            const dataArray = Object.entries(data?.total).map(([date, details]: any) => ({
                date,
                promo_0_sales: details?.promo_0.sales,
                promo_0_order: details?.promo_0.order,
                promo_1_sales: details?.promo_1.sales,
                promo_1_order: details?.promo_1.order
            }));
            const lastTenRecords = dataArray.splice(dataArray?.length-10,dataArray?.length );
            const dateLabels = lastTenRecords?.map((item:any) => item?.date);
            const formattedDates = dateLabels.map((date:any) => formatDate(date));
            const finalDates = formattedDates?.map((date: any) => [date]);
            const nonPromo = lastTenRecords?.map((item:any) => item?.promo_0_sales);
            const promo = lastTenRecords?.map((item:any) => item?.promo_1_sales);
            setPromotionsData({...promotionsData, dateLabels: finalDates, nonPromos: nonPromo, promos: promo});
            setLoader(false)
        } catch (error) {
            setLoader(true)
            setPromotionsData({...promotionsData, dateLabels: [['Jan 2023'], ['Feb 2023'], ['Mar 2023'], ['Apr 2023'], ['May 2023'],['Jun 2023']], nonPromos: [0,0,0,0,0,0], promos: [0,0,0,0,0,0]});
            setLoader(false)
        }
    }

    useEffect(() => {
        if(brand || brands[0]?.label){
            getPromotionsChartData(brand || brands[0]?.label);
        }
    },[brand, filter?.timeline, brands[0]?.label]);

    const handleNextData = () => {
        setPage(page + 1)
    }

    return (
        <>
            <div>
                <div style={{ padding: '16px' }}>
                    <p style={{ fontSize: '14px', fontWeight: 400 }}>Find out how many customers were acquired, lost or remained loyal to a brand between periods.</p>
                    <div style={{ display: 'flex', gap: '24px', marginTop: '10px' }}>
                        <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h2 className={styles.periodName}>Brand :</h2>
                            {/* <Select
                                value={brand || brands[0]?.value}
                                options={brands}
                                onChange={(e: any) => handleChange(e, 'brands')}
                                variant="outlined"
                                size='small'
                                multiple={false}
                                optionsType="default"
                                sx={{
                                    ...selectStyles
                                }}
                                Menustyle={{
                                    ...menustyles
                                }}
                            /> */}
                            <InfiniteSelect 
                                handleNextData={handleNextData}
                                data={brands}
                                label={brand || brands[0]?.label}
                                page={page}
                                setPage={setPage}
                                handleMenuItemClick={handlePromoBrandChange}
                            />
                        </div>
                        {/* <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h2 className={styles.periodName}>SKU :</h2>
                            <Select
                                value={sku || skus[0].value}
                                options={skus}
                                onChange={(e: any) => handleChange(e, 'skus')}
                                variant="outlined"
                                size='small'
                                multiple={false}
                                optionsType="default"
                                sx={{
                                    ...selectStyles
                                }}
                                Menustyle={{
                                    ...menustyles
                                }}
                            />
                        </div> */}
                        {/* <div style={{ marginTop: '50px', cursor: "pointer" }} className={styles.underLineText} onClick={getData}>
                            Run Report
                        </div> */}
                    </div>
                </div>
                <div style={{ padding: '25px', marginBottom: '100px', marginTop: '25px' }}>
                    {!loader ? <BarchartPromotions dateLabels={promotionsData?.dateLabels} promos={promotionsData?.promos} nonPromos={promotionsData?.nonPromos} /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </div>}
                </div>
            </div></>
    )
}