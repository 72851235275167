import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"; // Change to BrowserRouter if using normal routing
import LoginPage from "./pages/auth/Login";
import Sidebar from "./layout/Sidebar";
import { Insights } from "./pages/Monetise/Insights";
import Dashboard from "./pages/dashboard";
import { InsightsRoot } from "./pages/Monetise/Monetise/InsightsRoot";
import { CustomerBehaviour } from "./pages/Monetise/Insights/components/customer-behaviour";
import { Segmentation } from "./pages/Monetise/Insights/components/segmentation";
import { DataAnimator } from "./pages/Manage/DataAnimator";
import { Manage } from "./pages/Manage";
import { NotFound } from "./pages/notFound";
import { OnBoardForm } from "./pages/Manage/OnBoard";
import { Monetise } from "./pages/Manage/Subscribers";
import ManageCampaigns from "./pages/media/manage-campaigns/components/manage-compains";
import MeasurementAndAnalytics from "./pages/media/manage-campaigns/components/measurement-and-analysis";
import SupplyChain from "./pages/SupplyChain";
import RegisterPage from "./pages/auth/Register";
import EmailVerificationPage from "./pages/auth/Register/emailVerificationSentPage";
import PasswordSetup from "./pages/auth/Register/passwordSetup";
import StartPage from "./pages/auth/Login/startPage";
import CompanySetup from "./pages/auth/Login/companySetup";
import { Chatbot } from "./pages/chatbot";
import { NewDashboard } from "./pages/chatbot/newDashboard";
import EmailVerify from "./pages/auth/Register/emailVerify";
import OnboardPage from "./pages/auth/onboard";
import AmazonComponent from "./pages/auth/onboard/amazon";
import AccountActivation from "./pages/auth/onboard/accountActivation";
import ForgotPassword from "./pages/auth/forgot-password";
import EmptyDashboard from "./pages/dashboard/empty-dashboard";
import Connections from "./pages/dashboard/connections";
import { NewInsightsRoot } from "./pages/Monetise/Monetise/newInsightRoot";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/email-verification" element={<EmailVerificationPage />} />
        <Route path="/password-setup" element={<PasswordSetup />} />
        <Route path="/get-started" element={<StartPage />} />
        <Route path="/company-setup" element={<CompanySetup />} />
        <Route path="/auth/verify" element={<EmailVerify />} />
        <Route path="/onboard" element={<OnboardPage />} />
        <Route path="/amazon-onboard" element={<AmazonComponent />} />
        <Route path="/verify/amzn_oauth" element={<AccountActivation />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/reset-password" element={<PasswordSetup />} />

        <Route path="/*" element={<Sidebar />}>
          <Route element={<Dashboard />} path="dashboard" />
          <Route element={<EmptyDashboard />} path="home" />
          <Route element={<Connections />} path="connections" />
          <Route element={<NewDashboard />} path="new-dashboard" />
          <Route element={<Chatbot />} path="xylic-ai" />
          <Route element={<InsightsRoot />} path="insights" />
          <Route element={<NewInsightsRoot />} path="new-insights" />
          <Route element={<Insights />} path="insights/best-sellers" />
          <Route
            element={<CustomerBehaviour />}
            path="insights/customer-behaviour"
          />
          <Route element={<Segmentation />} path="insights/segmentation" />
          <Route element={<ManageCampaigns />} path="media/manage-campaign" />
          <Route
            element={<MeasurementAndAnalytics />}
            path="media/measurement-and-analytics"
          />
          <Route element={<Monetise />} path="subscribers" />
          <Route element={<Manage />} path="manage" />
          <Route element={<DataAnimator />} path="data-animator" />
          <Route element={<OnBoardForm />} path="on-boarding" />
          <Route element={<SupplyChain />} path="supplychain" />
          <Route element={<NotFound />} path="not-found" />
          <Route path="" element={<Navigate to="/home" replace />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
