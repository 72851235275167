import { Chart, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export const DailsySales = () => {
    const getData = () => {
        const labels = [
            'Aug 15', 'Aug 16', 'Aug 17', 'Aug 18', 'Aug 19', 'Aug 20', 'Aug 21', 'Aug 22', 'Aug 23', 'Aug 24',
            'Aug 25', 'Aug 26', 'Aug 27', 'Aug 28', 'Aug 29', 'Aug 30', 'Aug 31', 'Sep 01', 'Sep 02', 'Sep 03',
            'Sep 04', 'Sep 05', 'Sep 06', 'Sep 07', 'Sep 08', 'Sep 09', 'Sep 10', 'Sep 11', 'Sep 12', 'Sep 13', 'Sep 14'
        ];

        const datasets = [
            {
                label: 'Gross Revenue',
                data: [100000, 95000, 105000, 90000, 115000, 95000, 90000, 85000, 80000, 78000, 80000, 72000, 70000, 75000, 72000, 70000, 73000, 71000, 68000, 75000, 74000, 69000, 76000, 73000, 78000, 72000, 70000, 71000, 72000, 75000],
                borderColor: '#6cbfae',
                backgroundColor: 'transparent',
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 6,
                tension: 0.3,
            },
            {
                label: 'Net Profit',
                data: [32000, 30000, 31000, 34000, 30000, 32000, 31000, 30000, 29000, 30000, 31000, 31000, 30000, 31000, 32000, 30000, 31000, 32000, 33000, 31000, 30000, 29000, 30000, 31000, 32000, 31000, 30000, 31000, 32000, 30000],
                borderColor: '#e3d04b',
                backgroundColor: 'transparent',
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 6,
                tension: 0.3,
            },
            {
                label: 'Prev Revenue',
                data: [115000, 100000, 120000, 105000, 110000, 100000, 100000, 95000, 105000, 98000, 96000, 97000, 94000, 96000, 95000, 93000, 97000, 94000, 90000, 97000, 95000, 93000, 98000, 95000, 97000, 96000, 93000, 96000, 94000, 95000],
                borderColor: '#a3d197',
                borderDash: [5, 5],
                backgroundColor: 'transparent',
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 6,
                tension: 0.3,
            },
            {
                label: 'Product Pulse Events',
                data: [null, null, 0, null, null, 0, null, 0, null, 0, null, null, null, 0, null, null, 0, null, null, 0, null, null, null, 0, null, null, 0, null, 0],
                borderColor: 'transparent',
                backgroundColor: '#f47373',
                borderWidth: 0,
                pointRadius: 6,
                pointHoverRadius: 6,
                pointStyle: 'rectRounded',
            }
        ];


        return {
            labels: labels,
            datasets: datasets,
        };
    };

    const toptions: any = {
        chart: {
            type: 'bar',
        },
        elements: {
            point: {
                radius: 0
            }
        },
        interaction: {
            mode: 'nearest'
        },
        hover: {
            mode: 'nearest',
            intersect: true,
        },
        responsive: true,
        stacked: false,
        colors: ['#85D8DD', '#427ae3', '#3dc7d1', '#faa93e'],
        fill: {
            colors: ['#85D8DD', '#427ae3', '#3dc7d1', '#faa93e']
        },
        dataLabels: {
            enabled: false,
        },

        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            customLines: {
                x: {
                    afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (chart.tooltip._active && chart.tooltip._active.length) {
                            // find coordinates of tooltip
                            const activePoint = chart.tooltip._active[0];
                            const { ctx } = chart;
                            const { x } = activePoint.element;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            // draw vertical line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#1C2128';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
                y: {
                    afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (chart.tooltip._active && chart.tooltip._active.length) {
                            // find coordinates of tooltip
                            const activePoint = chart.tooltip._active[0];
                            const { ctx } = chart;
                            const { x } = activePoint.element;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            // draw vertical line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#1C2128';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min: 0,
                position: 'left',
                title: {
                    display: true,
                    text: 'Sales',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5
                },
                grid: {
                    display: true,
                },
                ticks: {
                    // stepSize: 1000// <----- This prop sets the stepSize
                }
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                stacked: true,
                autoSkip: false,
                grid: {
                    display: true,
                },
                ticks: {
                    autoSkip: false
                }
            },
        },
        maintainAspectRatio: false,
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return context;
                    } else {
                        return context[1] - context[0];
                    }
                }
            }
        },
    };



    return (
        <div>
            <Chart
                type="line"
                data={getData()}
                width={'100%'}
                height={'450px'}
                options={{ ...toptions }}
            />
        </div>
    );
};
