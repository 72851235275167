import CommonTable from "../../../../components/organisms/CommonTable";
import sale1 from '../../../../assets/svg/sale.svg';
import sale2 from '../../../../assets/svg/sale2.svg'
import sale3 from '../../../../assets/svg/sale3.svg'
import sale4 from '../../../../assets/svg/sale4.svg'
import sale5 from '../../../../assets/svg/sale5.svg'
import desc from '../../../../assets/svg/description.svg'

const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
export const ActiveCol: any = ({ filter, page }: any) => {
    return (
        [
            {
                id: 'id',
                columnLabel: '#',
                minWidth: 40,
                render: (row: any, index: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {(page === 1 ? (index + 1) : (((page-1) * 25) + index + 1)) || 1 }
                    </p>
                ),
            },
            {
                id: 'name',
                columnLabel: 'Name',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row.product}
                    </p>
                ),
            },
            {
                id: 'sales',
                columnLabel: filter?.volume === 'volume' ? 'Quantity' : 'Sales',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style, }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px', gap: '24px' }}>
                            {/* <img src={row.img} alt="" width={24} /> */}
                            {filter?.volume === 'volume' ? row.order_quantity_current.toFixed(1) : `$ ${row.sales_current.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        </div>
                    </p>
                ),
            },
            {
                id: 'period',
                columnLabel: 'Previous Period',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {filter?.volume === 'volume' ?
                            <div style={{ color: row.change_in_order_quantity >= 0 ? 'rgb(88, 199, 2)' : 'rgb(246, 0, 0)' }}>{row.change_in_order_quantity >= 0 ? '+' : ''} {row.change_in_order_quantity}</div>
                            : <div style={{ color: row.change_in_sales >= 0 ? 'rgb(88, 199, 2)' : 'rgb(246, 0, 0)' }}>{row.change_in_sales >= 0 ? '+' : ''} {row.change_in_sales}</div>
                        }
                    </p>
                ),
            },
            {
                id: 'price',
                columnLabel: 'Average Price',
                textAlign: 'center',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        $ {row.avg_sales_current?.toFixed(1)}/-
                    </p>
                ),
            },
            // {
            //     id: 'report',
            //     columnLabel: 'Report',
            //     minWidth: 40,
            //     render: (row: any) => (
            //         <p color='#051433' onClick={() => { }} style={{ ...style,gap:'8px' }}>
            //             <img src={desc} alt="" width={24} />
            //             <p style={{textDecoration:'underline'}}>{row.report}</p>
            //         </p>
            //     ),
            // },
        ]
    )
}

export const rows = [
    { id: '1', name: 'Camel', sales: '24,755', period: '0.14', price: 'INR 20,000 /-', report: 'report2456.pdf', status: 'positive', img: sale1 },
    { id: '2', name: 'P&G', sales: '35,786', period: '0.14', price: 'INR 30,000 /-', report: 'report5256.pdf', status: 'positive', img: sale2 },
    { id: '3', name: 'Chings', sales: '254', period: '0.14', price: 'INR 60,000 /-', report: 'report8456.pdf', status: 'negative', img: sale3 },
    { id: '4', name: 'Organic', sales: '1,22,558', period: '0.14', price: 'INR 80,000 /-', report: 'report5456.pdf', status: 'positive', img: sale4 },
    { id: '5', name: 'Beats', sales: '500', period: '0.14', price: 'INR 90,000 /-', report: 'report3456.pdf', status: 'negative', img: sale2 },
    { id: '6', name: 'GoPro', sales: '9,728', period: '0.14', price: 'INR 90,000 /-', report: 'report8406.pdf', status: 'negative', img: sale1 },
    { id: '7', name: 'Huawel', sales: '20,889', period: '0.14', price: 'INR 20,000 /-', report: 'report2456.pdf', status: 'positive', img: sale5 },
    { id: '8', name: 'Dyson', sales: '256', period: '0.14', price: 'INR 40,000 /-', report: 'report3056.pdf', status: 'negative', img: sale3 },
    { id: '9', name: 'Beats', sales: '500', period: '0.14', price: 'INR 90,000 /-', report: 'report3456.pdf', status: 'negative', img: sale2 },
    { id: '10', name: 'GoPro', sales: '9,728', period: '0.14', price: 'INR 90,000 /-', report: 'report8406.pdf', status: 'negative', img: sale1 },
    { id: '11', name: 'Huawel', sales: '20,889', period: '0.14', price: 'INR 20,000 /-', report: 'report2456.pdf', status: 'positive', img: sale5 },
    { id: '12', name: 'Dyson', sales: '256', period: '0.14', price: 'INR 40,000 /-', report: 'report3056.pdf', status: 'negative', img: sale3 },
    { id: '13', name: 'Beats', sales: '500', period: '0.14', price: 'INR 90,000 /-', report: 'report3456.pdf', status: 'negative', img: sale2 },
    { id: '14', name: 'GoPro', sales: '9,728', period: '0.14', price: 'INR 90,000 /-', report: 'report8406.pdf', status: 'negative', img: sale1 },
    { id: '15', name: 'Huawel', sales: '20,889', period: '0.14', price: 'INR 20,000 /-', report: 'report2456.pdf', status: 'positive', img: sale5 },
    { id: '16', name: 'Dyson', sales: '256', period: '0.14', price: 'INR 40,000 /-', report: 'report3056.pdf', status: 'negative', img: sale3 },
];

export const DataTable = ({ data, handleRowClick, filter, loader, page }: any) => {
    return (
        <div>
            <CommonTable {...{
                cols: ActiveCol({ filter, page }),
                data: data,
                tableData: data,
                selection: false,
                onRowClick: handleRowClick,
                loading: loader
            }} />
        </div>
    )
}