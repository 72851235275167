import { Node, Input } from "@nodl/core";
import { DataTempSchema } from "../Schema/DataTemp";

class TransactionOutput extends Node {
  name = "Transactions Output";

  inputs = {
    source: new Input({ name: "input", type: DataTempSchema, defaultValue: {} }),
   
  };
  uid = "output-transaction";
  outputs = {}

  outputs_: any = {
    "Amount": "",
    "Brand Name": "",
    "Product Name": "",
    "Qunatity": "",
    "User": "",
    "Payement Method": "",
    "Delivery Mode": ""
  };

  getMaps() {
    return this.outputs_
  }

  getColumns() {
    const val : any = this.inputs.source.value
    return val?.columns || []
  }

  setMap(key: any, val: any) {
    this.outputs_[key] = val
  }

  constructor() {
    super();
  }
}

export { TransactionOutput };
