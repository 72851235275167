import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
	mainHeader: 'Dashboard',
	subHeader: '',
	isDashboard: true,
	permissions: {},
	loading: false, userName: '',
	showSide:false
};

export const appContextSlice = createSlice({
	name: 'tabpanel',
	initialState,
	reducers: {
		switchDashboard: (state, action) => {
			state.isDashboard = action.payload?.isEnabled
		},
		setUserNameApi: (state, action) => {
			state.userName = action.payload
		},
		onMainTabChange: (state, action) => {
			state.mainHeader = action.payload?.name;
		},
		onsubTabChange: (state, action) => {
			state.subHeader = action.payload?.name;
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		setLoadingP: (state, action) => {
			state.loading = action.payload;
		},
		setShowSidebar: (state, action) => {
			state.showSide = action.payload;
		},
	}
});

export const { onMainTabChange, onsubTabChange, switchDashboard, setPermissions, setLoadingP,setUserNameApi,setShowSidebar } = appContextSlice.actions;
export default appContextSlice.reducer;