import { configureStore } from '@reduxjs/toolkit';
import appContextReducer from './header/reducer';
import nodeMenuReducer from './data-animator/right-side-menu';
import sidebarReducer from './sidebar/reducer'
import toastReducer from './toastSlice'

const store = configureStore({
	reducer: {
		headerContext: appContextReducer,
		nodeMenuContext: nodeMenuReducer,
		sidebarContext:sidebarReducer,
		toastContext:toastReducer
	}
});

const restoreDataFromLocal = () => {
	const userInfo = localStorage.getItem('user_info');
	// const accessToken = localStorage.getItem('access_token');
	if (userInfo) {
		// const parsed: any = JSON.parse(userInfo);
		// store.dispatch(setUserContext({
		// 	isAuthenticated: accessToken ? true : false,
		// 	userInfo: parsed
		// }));
	}
};

restoreDataFromLocal();

export default store;