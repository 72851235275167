import { CircuitStore, Circuit } from "@nodl/react";
import { Node, Input, Output, schema } from '@nodl/core';
import { Observable, map } from 'rxjs';

import { DataTempSchema } from "../Schema/DataTemp";


class TypeConvertor extends Node {
    name = 'Type Convertor';
    uid = "operation-convertor"
    inputs = {
        target: new Input({ name: 'input', type: DataTempSchema, defaultValue: {} }),
    };

    outputs = {
        data: new Output({
            name: 'output',
            type: DataTempSchema,
            observable: new Observable((subscriber) => {
                subscriber.next({ columns: [] })
            })
        })
    };
}

export { TypeConvertor }


