import styles from "./sidebarLayout.module.scss";
import GestureIcon from "@mui/icons-material/Gesture";
import AddIcon from "@mui/icons-material/Add";

export const SideLayout = ({
  onAdd,
}: {
  onAdd: (id: string, x: number, y: number) => void;
}) => {
  const sidebarData = [
    {
      label: "Inputs",
      children: [
        {
          label: "Data Connector",
          id: "data-connector",
        },
      ],
    },
    {
      label: "Operations",
      children: [
        {
          label: "Drop Column",
          id: "drop-column",
        },
        {
          label: "Join Tables",
          id: "join-tables",
        },
        {
          label: "Rename Column",
          id: "rename-column",
        },
        { label: "Type Convertor", id: "type-convert" },
        { label: "Split columns (string)", id: "operation-split" },
      ],
    },
    {
      label: "Outputs",
      children: [
        {
          label: "Users",
          id: "output-user",
        },
        {
          label: "Transactions",
          id: "output-transaction",
        }],
    }
  ];

  return (
    <>
      <div className={`sidebar`}>
        <ul className="sidebarRightSideContainer">
          <div className="sidebarInsightsHeader">
            <GestureIcon className="insightsHeader" />
            <p className="insightsHeader select-none">Data Animator</p>
          </div>
          <div className={styles.scrollContainer}>
            {sidebarData.map((item) => {
              return (
                <div>
                  <div className={styles.MainTextHeader}>{item.label}</div>
                  <div>
                    {item.children.map((item) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "5px 14px",
                          }}
                        >
                          <li className={styles.subLabelText}>{item.label}</li>
                          <div
                            className={styles.addButton}
                            onClick={(e) => {
                              const x = -((window.innerWidth - 260) / 2 - 150);
                              const center = ((window.innerHeight / 2) + 70);
                              const y = -(e.clientY - center);
                              onAdd(item.id, x, y);
                            }}
                          >
                            <AddIcon />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </ul>
      </div>
    </>
  );
};
