import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormInput from "../../../components/atoms/FormInputField";
import { CustomButton } from "../../../components/atoms/button/button";
import styles from "./register.module.scss";
import { Button } from "@mui/material";
import {
  CheckCircleOutline,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addToast } from "../../../store/toastSlice";
import Loader from "../../../components/atoms/loader";
import { apiRequest } from "../../../services/onboard";
import { useDispatch } from "react-redux";
import { validatePassword } from "../../../services/insights";
import PasswordValidation from "../../../utils/passwordValidation";

const defaultTheme = createTheme();

export default function PasswordSetup() {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // Extract token from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validatePassword(password)) {
      setError("");
      return;
    }

    if (!validatePassword(confirmPassword)) {
      setError("");
      return;
    }

    if (!password || !confirmPassword) {
      setError("Both fields are required.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Validate password
    const validationErrors = !validatePassword(password);
    if (validationErrors) {
      setError("Password does not meet the required criteria.");
      return;
    }

    try {
      await apiRequest({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/auth/forgot_password_change`,
        data: {
          newpassword: password,
          token: token,
        },
        dispatch,
        setLoading,
        successMessage: "Password reset successful",
        errorMessage: "Failed to reset password. Please try again.",
        successRedirect: "/login",
        navigate,
      });
    } catch (error) {
      console.error("An error occurred while resetting the password:", error);
      // Additional error handling can be done here if necessary.
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", overflow: 'auto' }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: "#5B47F5",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="max-sm:hidden"
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Grid
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}

            >
              <p style={{ color: "white", fontWeight: 800, fontSize: "40px" }}>
                Xylic
              </p>
              <p style={{ color: "white", fontWeight: 400, fontSize: "20px" }}>
                Build, Manage and Optimise <br />
                multiple revenue streams from a single platform
              </p>
              <Button
                sx={{
                  padding: "10px",
                  background: "white",
                  borderRadius: "20px",
                  fontSize: "14px",
                  width: "135px",
                  height: "37px",
                  "&:hover": {
                    background: "white",
                  },
                }}
                onClick={() => {
                  window.open("https://www.xylic.ai/");
                }}
              >
                Read More
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0px 80px",
            gap: "24px",
            background: "white",
          }}
          className={`${styles.registerContainer} max-sm:p-8`}
        >
          {/* <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '24px',
                            background: '#D8F0DE',
                            padding: '16px',
                            marginBottom: '20px',
                            borderRadius: '8px',
                        }}
                    >
                        <CheckCircleOutline fill="#5DB470" />
                        <h1 style={{ fontSize: '18px', fontWeight: 500 }}>Email Verified Successfully</h1>
                        <Close />
                    </Grid> */}

          <Typography component="h1" variant="h5" className={styles.headerText}>
            Set your password
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            display="flex"
            gap="24px"
            flexDirection="column"
          >
            <Grid sx={{ height: "40px" }} />

            <FormInput
              variant="filled"
              size="small"
              type={show ? "text" : "password"}
              label="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endIcon={
                show ? (
                  <VisibilityOff
                    onClick={() => setShow(!show)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setShow(!show)}
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              sx={{}}
            />
            {/* {(error || password) && (
              <PasswordValidation
                password={password}
                styles={{ margin: "none" }}
              />
            )} */}

            <FormInput
              variant="filled"
              size="small"
              type={show2 ? "text" : "password"}
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              endIcon={
                show2 ? (
                  <VisibilityOff
                    onClick={() => setShow2(!show2)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setShow2(!show2)}
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              sx={{}}
            />
            {(error || confirmPassword || password) && (
              <PasswordValidation
                password={password}
                styles={{ margin: "none" }}
                confirmPassword={confirmPassword}
                reset={true}
              />
            )}

            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}

            <Grid sx={{ height: "80px" }} />
            <CustomButton
              type="submit"
              disabled={
                loading ||
                !validatePassword(password && confirmPassword) ||
                password !== confirmPassword
              }
            >
              {loading ? <Loader size={25} /> : "Confirm"}
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
