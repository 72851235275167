import React from 'react';
import './styles.scss'; // Import your SCSS file for styling

const PyramidChart = () => {
  return (
    <div className="chart-container">
      <div className="funnel-chart">
        <div className="funnel-layer">Impressions</div>
        <div className="funnel-layer">Clicks</div>
        <div className="funnel-layer">Carts</div>
        <div className="funnel-layer">Purchase</div>
        <div className="funnel-layer">Repeats</div>
      </div>
      <div className="search-query-container">
        <div className="search-label">
          Search Query <span className="info-icon">?</span>
        </div>
        <div className="query-box">hamsa evil eye robe</div>
      </div>
    </div>
  );
};

export default PyramidChart;
