import React, { useEffect } from "react";
import styles from "../DataAnimator/sidebarLayout.module.scss";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  onMainTabChange,
  onsubTabChange,
} from "../../../store/header/reducer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import SubtabsHeader from "../SubtabsHeader";

const NBtn = styled(Button)({
  backgroundColor: "#5F46FF",
  color: "white",
  ":hover": {
    backgroundColor: "#5F46FF",
  },
});

export const Chip = ({ item, selected }: any) => {
  return (
    <div
      style={{
        height: "32px",
        padding: "6px 12px",
        background: selected ? "rgba(93, 66, 255, 0.08)" : "rgb(243, 243, 248)",
        color: selected ? "rgb(100, 77, 237)" : "rgb(128, 129, 146)",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        borderRadius: "6px",
        width: "fit-content",
      }}
    >
      {item}
    </div>
  );
};

function createData(
  customer_name: string,
  email: string,
  modules: string,
  settings: number
) {
  return { customer_name, email, modules, settings };
}

const rows = [
  createData(
    "P&G",
    "monetize@pandg.com",
    "Insights, Personalized ads, Collaboration",
    0
  ),
  createData(
    "Loreal",
    "revenue@loreal.com",
    "Insights, Collaboration, Tests & surveys",
    0
  ),
  createData(
    "Haldiram",
    "contact@haldiram.com",
    "Insights, Personalized ads",
    0
  ),
  createData(
    "Unilever",
    "admin@unilver.com",
    "Insights, Personalized ads, Collaboration",
    0
  ),
];

const Monetise = () => {
  const sidebarData2 = [
    {
      label: "Recently Added",
      children: [
        {
          label: "Halidiram",
        },
        {
          label: "P&G",
        },
      ],
    },
    {
      label: "Solutions",
      children: [
        {
          label: "Collaboration",
        },
        {
          label: "Sharing",
        },
        {
          label: "Insights",
        },
        { label: "Media" },
        { label: "A/B Testing" },
      ],
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(onMainTabChange({ name: "Manage"}));
    dispatch(onsubTabChange({ name: "Subscribers"}));  
  }, [])


  return (
    <>
      <div className="sidebar">
        <ul>
          <ul className="sidebarRightSideContainer">
            <div className={styles.scrollContainer2}>
              {sidebarData2.map((item) => {
                return (
                  <div>
                    <div className={styles.MainTextHeader}>{item.label}</div>
                    <Grid container style={{ padding: "16px" }}>
                      {item.children.map((item, index) => {
                        return (
                          <Grid item padding={"5px"}>
                            <Chip
                              {...{
                                item: item.label,
                                selected: [2, 6, 8].includes(index),
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                );
              })}
            </div>
          </ul>
        </ul>
      </div>
      <div>
        <SubtabsHeader />
        <div className="p-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell>Customer name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Modules</TableCell>
                  <TableCell>Settings</TableCell>
                  <TableCell align="right">Customer View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, ix) => (
                  <TableRow
                    key={ix}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.customer_name}
                    </TableCell>
                    <TableCell scope="row">{row.email}</TableCell>
                    <TableCell scope="row">{row.modules}</TableCell>
                    <TableCell scope="row">
                      <div className="cursor-pointer text-[#5F46FF] active:text-[black]">
                        <SettingsIcon />
                      </div>
                    </TableCell>
                    <TableCell scope="row" align="right">
                      <NBtn
                        onClick={() => {
                          if (window) {
                            window.open("/insights", "_blank")?.focus();
                          }
                        }}
                      >
                        Open
                      </NBtn>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export { Monetise };
