import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import { ReactComponent as LeftArrow } from "../../../assets/svg/leftArrow.svg";
import ListItemText from '@mui/material/ListItemText';
import { Grid, Input, InputBase } from '@mui/material';
import styles from "./styles.module.scss";
import { getUtilsData } from '../../../services/insights';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import Loader from '../../atoms/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { onAgeGroupChange, onDeliveryChange, onLocationChange, onProductChange, onCategoryChange, onBrandChange } from '../../../store/sidebar/reducer';
import { useDispatch } from 'react-redux';

export default function MultipleSelectChip({ label, options, disable, path, data }: any) {
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [names, setNames] = useState<any>(data || []);
  const [open, setOpen] = useState(false)
  const [finalList, setFinalList] = useState(data)
  const dropdownRef: any = React.useRef(null);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch()

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && open) {
        // setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if(label === 'Category'){
      if (data.length > 0) {
        dispatch(onCategoryChange({ category: [data[0]] }));
        setPersonName([data[0]])
        setNames(data)
        setFinalList(data)
      }
    }
    if(label === 'Brand'){
      if(data.length > 0){
        dispatch(onBrandChange({ brand: [data[0]] }));
        setPersonName([data[0]])
        setFinalList(data)
        setNames(data)
      }
    }
    if(label === 'Region & city'){
      if(data.length > 0){
        dispatch(onLocationChange({ location: [data[7]] }));
        setPersonName([data[7]])
        setFinalList(data)
        setNames(data)
      }
    }
  }, [data, label])




  const handleMenuItemClick = (name: string) => {
    const selectedIndex = personName.indexOf(name);
    let newPersonName: string[] = [];
    let data: any = [];
    if (selectedIndex === -1) {
      newPersonName = [...personName, name];
      data = names?.filter((item: any) => name !== item)
      setFinalList(data)
      setNames(data)
    } else {
      data = [...names, name]
      setNames(data)
      setFinalList(data)
      newPersonName = personName.filter((val, index) => name !== val);
    }
    if (label === 'Delivery') {
      dispatch(onDeliveryChange({ delivery: [...newPersonName] }));
    } else if (label === 'Brand') {
      dispatch(onProductChange({ products: [...newPersonName] }));
      dispatch(onBrandChange({ brand: [...newPersonName] }));
    } else if (label === 'Region & city') {
      dispatch(onLocationChange({ location: [...newPersonName] }));
    } else if (label === 'Age Range') {
      dispatch(onAgeGroupChange({ age_group: [...newPersonName] }));
    } else if (label === 'Category') {
      dispatch(onCategoryChange({ category: [...newPersonName] }));
    }
    setPersonName(newPersonName);
  };

  const handleGetData = async () => {
    const response: any = await getUtilsData(path, page);
    const data2 = response?.results || [];
    const data = data2.filter((item: any) => !(personName.includes(item)))
    if (data.length > 0) {
      setNames((prev: any) => {
        return [
          ...prev, ...data
        ]
      })
      setFinalList((prev: any) => {
        return [
          ...prev, ...data
        ]
      })
    }
  }

  React.useEffect(() => {
    if (open && data.length === 0) {
      handleGetData()
    }
  }, [page])


  const handleClickOpen = (e: any) => {
    e?.stopPropagation()
    setOpen(!open)
    setPage(0)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    const filteredNames = finalList.filter((name: any) =>
      name.toLowerCase().includes(term)
    );
    setSearch(term)
    setNames(filteredNames);
  };

  const handleNextData = () => {
    setPage(page + 1)
  }
  return (
    <div style={{ pointerEvents: disable ? 'none' : 'all', opacity: disable ? 0.5 : 1, width: '100%' }}>
      <div style={{ padding: '8px', paddingLeft: '24px', cursor: 'pointer' }} onClick={(e) => handleClickOpen(e)}>
        <InputLabel style={{ position: 'relative', display: 'flex', cursor: 'pointer', justifyContent: 'space-between', width: 220, color: 'rgb(94, 92, 113)', fontSize: '14px', fontWeight: 400,fontFamily:'Inter' }}>
          {label} {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </InputLabel>
      </div>
      {open && (
        <Grid ref={dropdownRef} sx={{
          width: 220, padding: '0px', borderRadius: '4px', overflow: 'hidden', position: 'relative', marginLeft: '20px', marginTop: '-8px',
          maxHeight: '300px',
          boxShadow: 0,
          fontFamily: 'Poppins',
          border: '1px solid #EAECF0',
        }}>
          <Grid>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', padding: '4px', paddingTop: '2px' }}>
              {personName.map((value) => (
                <Chip key={value} label={value} onDelete={() => handleMenuItemClick(value)} />
              ))}
            </Box>
            <InputBase
              placeholder='Type here to search'
              onChange={handleSearch}
              value={search}
              sx={{
                width: '100%',
                padding: '4px',
                paddingTop: '0px',
                // paddingLeft: '4px',
                borderBottom: '1px solid #EAECF0',
                '& .MuiInputBase-input::placeholder': {
                  color: 'rgb(200, 197, 208)',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.5px'
                }
              }}
            />
          </Grid>
          <Grid id="scrollableDiv" className={styles.dropdownList} style={{ padding: '0px' }}>
            <InfiniteScroll
              dataLength={names?.length} //This is important field to render the next data
              next={handleNextData}
              hasMore={true}
              scrollableTarget="scrollableDiv"
              loader={<div style={{ height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loader size={25} />
              </div>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {names?.map((name: any, index: any) => (
                <MenuItem
                  key={name}
                  value={name}
                  onClick={() => handleMenuItemClick(name)}

                  sx={{
                    borderBottom: '1px solid #EAECF0'
                  }}
                >
                  <ListItemText primary={name} style={{ padding: '0px', fontFamily: 'Poppins', }} />
                </MenuItem>
              ))}
            </InfiniteScroll>

            {/* {loading && <Grid sx={{
              height: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Loader size={25} />
            </Grid>} */}
          </Grid>
        </Grid>
      )}
    </div>
  );
}