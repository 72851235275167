import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
} from "@mui/material";
import { Box, padding } from "@mui/system";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import SubtabsHeader from "../SubtabsHeader";
import { useDispatch, useSelector } from "react-redux";
import { onMainTabChange, onsubTabChange } from "../../../store/header/reducer";
import { SideLayout } from "./sideLayout";
const NBtn = styled(Button)({
  backgroundColor: "#5F46FF",
  color: "white",
  ":hover": {
    backgroundColor: "#5F46FF",
  },
});
const OnBoardForm = () => {
  // Define state for the form inputs
  const [customerName, setCustomerName] = useState("");
  const [modules, setModules] = useState([]);
  const [email, setEmail] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [contractFile, setContractFile]: [any, any] = useState(null);
  const navigate = useNavigate();
  // Define the options for the multi-select drop down
  const moduleOptions = [
    "Insights",
    "Personalized Ads",
    "Collaboration",
    "Tests & Surveys",
  ];
  const subTab = useSelector((state: any) => state?.headerContext?.subHeader);
  const mainTab = useSelector((state: any) => state?.headerContext?.mainHeader);

  // Handle changes in the multi-select drop down
  const handleModulesChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setModules(typeof value === "string" ? value.split(",") : value);
  };

  // Handle file selection
  const handleFileChange = (event: any) => {
    setContractFile(event.target.files[0]);
  };
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onMainTabChange({ name: "Manage" }));
    dispatch(onsubTabChange({ name: "On Boarding" }));
  }, [])

  // Handle form submission
  const handleSubmit = (event: any) => {
    event.preventDefault();
    navigate("/");
    // Perform form submission logic here
  };
  return (
    <div className="bg-white h-[100vh]">
      <SideLayout />
      <div >
        <SubtabsHeader style={{ paddingLeft: 300 }} />
      </div>
      <div className="flex justify-center  p-8">
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: 800,
            minWidth: 600
          }}
        >
          <span className="font-bold text-center text-lg">
            New Subscriber On-Boarding
          </span>
          {/* Customer Name */}
          <TextField
            label="Customer Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {/* Modules (Multi-select Chip) */}
          <FormControl fullWidth>
            <InputLabel id="module-select-label">Select Module</InputLabel>
            <Select
              labelId="module-select-label"
              id="module-select"
              multiple
              value={modules}
              onChange={handleModulesChange}
              input={<OutlinedInput label="Select Module" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {moduleOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Primary Contact */}
          <TextField
            label="Primary Contact"
            value={primaryContact}
            onChange={(e) => setPrimaryContact(e.target.value)}
            required
          />

          {/* Secondary Contact */}
          <TextField
            label="Secondary Contact"
            value={secondaryContact}
            onChange={(e) => setSecondaryContact(e.target.value)}
          />

          {/* File Upload Button */}
          <Box>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <Button
                sx={{
                  backgroundColor: "#5F46FF",
                  ":hover": {
                    backgroundColor: "#5F46FF",
                  },
                }}
                variant="contained"
                component="span"
              >
                Upload Contract
              </Button>
            </label>
            {contractFile && (
              <Box mt={2}>
                <Box component="span">{contractFile.name}</Box>
              </Box>
            )}
          </Box>
          {/* Submit Button */}
          <div className="flex items-center justify-center mt-3">
            <Box>
              <NBtn type="submit" variant="contained">
                Launch
              </NBtn>
            </Box>
          </div>
        </Box>
      </div>
    </div>
  );
};

export { OnBoardForm };
