import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/system";
import { Button, InputAdornment, TextField, Box } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";

const CsvDialog = styled(Dialog)({
  zIndex: 10000,
  ".MuiPaper-root": {
    width: "40%",
  },
});

const NBtn = styled(Button)({
  backgroundColor: "#5F46FF",
  color: 'white',
  ":hover": {
    backgroundColor: "#5F46FF",
  },
});

const CsvInitDialog = ({
  open,
  handleClose,
  handleAdd,
}: {
  open: boolean;
  handleClose: () => void;
  handleAdd: (name: string, columnNames: any) => void;
}) => {
  const [selected, setSelected] = useState("");
  const [seprator, setSeprator] = useState(",")
  const [page, setPage] = useState(0);
  const [selectedFile, setSelectedFile]: [any, any] = useState(null);
  const [name, setName] = useState("");
  const [isNameErr, setNameErr] = useState(false);
  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!open) {
      setSelected("");
      setSelectedFile(null);
      setPage(0);
      setNameErr(false)
      setName("")
    }
  }, [open]);

  return (
    <CsvDialog
      onClose={() => {
        handleClose();
      }}
      open={open}
    >
      <DialogContent>
        {page == 0 && (
          <div className="flex flex-col">
            <div>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <span className="mt-2">Select data source</span>
            <div className="w-full border-solid border-2 p-2 mb-2 mt-1">
              <div
                onClick={() => {
                  if (selected == "csv") {
                    setSelected("");
                  } else {
                    setSelected("csv");
                  }
                }}
                className={`flex flex-col items-center justify-center w-1/4 h-16 bg-[#fcf8ff] p-2 rounded border cursor-pointer active:scale-90 ${
                  selected == "csv" ? "border-[#261B71]" : ""
                }`}
              >
                <img src="/csv.png" />
                <span className="text-md select-none font-bold">CSV</span>
              </div>
            </div>
            <div className="flex justify-end">
              <NBtn
                onClick={() => setPage(1)}
                disabled={selected == ""}
                variant="contained"
              >
                Next
              </NBtn>
            </div>
          </div>
        )}
        {page == 1 && (
          <div className="mt-2">
            <div className="flex flex-col w-full p-2 mb-2 mt-1">
              <div>
                <TextField
                  id="outlined-basic"
                  onChange={(e) => setName(e.target.value)}
                  label="Name"
                  error={isNameErr}
                  helperText={isNameErr ? "Please enter name to continue":""}
                  value={name}
                  variant="outlined"
                />
              </div>
              <div className="mt-4">
                <TextField
                  id="outlined-basic"
                  value={seprator}
                  onChange={(e:any)=> setSeprator(e.target.value)}
                  label="Separator"
                  variant="outlined"
                />
              </div>
              <Box mt={2}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  id="file-upload"
                />
                <label htmlFor="file-upload">
                  <Button
                    sx={{
                      backgroundColor: "#5F46FF",
                      ":hover": {
                        backgroundColor: "#5F46FF",
                      },
                    }}
                    variant="contained"
                    component="span"
                  >
                    Choose Sample File
                  </Button>
                </label>
                {selectedFile && (
                  <Box mt={2}>
                    <Box component="span">
                      {selectedFile.name}
                    </Box>
                  </Box>
                )}
              </Box>
            </div>
            <div className="flex justify-end">
              <NBtn
                onClick={() => {
                  if(name==""){
                      setNameErr(true)
                      return;
                  }
                  const file = selectedFile;
                  if (file) {
                    const reader = new FileReader();
        
                    reader.onload = (e: any) => {
                        const fileContent = e.target.result;
                        // Split the content based on new lines to get rows
                        const rows = fileContent.split('\n');
                        if (rows.length > 0) {
                            // Use the provided separator to split the first row to get column names
                            const firstRow = rows[0];
                            const columnNames = firstRow.split(seprator);
                            handleAdd(name, columnNames);
                        }
                    };
        
                    reader.readAsText(file);
                } 
                  
                }}
                disabled={selected == "" && selectedFile == null}
                variant="contained"
              >
                Add
              </NBtn>
            </div>
          </div>
        )}
      </DialogContent>
    </CsvDialog>
  );
};

export { CsvInitDialog };
