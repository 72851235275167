import { SearchInput } from '../../../../../components/atoms/search';
import styles from './styles.module.scss';
import { ReactComponent as StarIcon } from '../../../../../assets/svg/start.svg';
import { ReactComponent as ExportIcon } from '../../../../../assets/svg/download.svg';
import { ReactComponent as ShareIcon } from '../../../../../assets/svg/share.svg';
import Select from '../../../../../components/atoms/Select';
import { CustomerSwitch } from './customer-switch';
import { useEffect, useState } from 'react';
import { LoyalityCS } from './loyality';
import { Promotions } from './promotions';
import { Overlap } from './overlap';
import SubtabsHeader from '../../elements/Subtabs/SubtabsHeader';
import { ReactComponent as DownArrowIcon } from "../../../../../assets/svg/downarr.svg";
import { useInsights } from '../../useInsights';
import { getCustomerBrandData, getPromoBrands } from '../../../../../services/insights';
import { timeline } from '../../../../../constants/utils';

export const CustomerBehaviour = () => {
    const [item, setItem] = useState<any>(0);
    const { getTimeLines, getBrands, getSkus } = useInsights();
    const [timeLines, setTimeLines] = useState(timeline?.map((time: string) => {
        return {
            label: time,
            value: time
        }
    }));
    const [brands, setBrands] = useState<any>([
    ]);
    const [promoBrands, setPromoBrands] = useState<any>([]);
    const [overlapParams, setOverlapParams] = useState<any>([]);
    const [skus, setSkus] = useState<any>([]);
    const [filter, setFilter] = useState<any>({
        timeline: '15 days',
        brand: ''
    });

    const [types, setTypes] = useState('product_category')
    const [page, setPage] = useState(0);
    const [promoPage, setPromoPage] = useState(0);

    const handleChangeItem = (e: any, selecion: string) => {
        const value = e.target.value;
        switch (selecion) {
            case 'types':
                setItem(value)
                break;
            case 'timeline':
                setFilter({ ...filter, timeline: value })
                break;
            case 'brand':
                setFilter({ ...filter, brand: value })
                break;
            case 'category':
                setTypes(e.target.value)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if(item === 3){
            (async () => {
                const apiBrands: any = await getPromoBrands({
                    offset: [promoPage],
                    limit: [10]
                });
                const brandResult = apiBrands?.results?.map((brand: string) => {
                    return {
                        label: brand,
                        value: brand
                    }
                }) || [];
                setPromoBrands([...promoBrands,...brandResult]);
            })();
        }
            // (async () => {
            //     const skus = await getSkus();
            //     const result = skus?.map((sku: string) => {
            //         return {
            //             label: sku,
            //             value: sku
            //         }
            //     });
            //     setSkus([...result]);
            // })();
    }, [item, promoPage])

    useEffect(() => {
        const getData = async () => {
            if (item === 1 || item === 3) {
                return await getBrands()
            } else if (item === 0) {
                return await getCustomerBrandData('',page)
            }
        }
        (async () => {
            // const timeline = await getTimeLines();
            const result: any = timeline?.map((time: string) => {
                return {
                    label: time,
                    value: time
                }
            });
            setTimeLines([...result]);

            const apiBrands: any = await getData();
            const brandResult = apiBrands?.results?.map((brand: string) => {
                return {
                    label: brand,
                    value: brand
                }
            }) || [];
            setBrands([...brands, ...brandResult]);
        })();
    }, [item, page])
    return (
        <>
            <SubtabsHeader />
            <div style={{ padding: '16px' }}>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <div style={{ display: "flex", alignItems: "center", gap: '16px' }}>
                        <div style={{ width: '265px' }}><SearchInput /></div>
                        <Select
                            value={item}
                            options={[
                                { label: 'Customer switch', value: 0 },
                                { label: 'Loyalty ', value: 1 },
                                { label: 'Overlap', value: 2 },
                                { label: 'Promotions', value: 3 }

                            ]}
                            variant="outlined"
                            size='small'
                            multiple={false}
                            optionsType="default"
                            onChange={(e: any) => handleChangeItem(e, 'types')}
                            sx={{
                                color: '#fff !important',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                borderRadius: '8px',
                                paddingTop: '3px',
                                width: '223px',
                                height: '36px',
                                border: 'none',
                                background: '#5F46FF !important',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    color: '#fff !important',

                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .MuiSelect-outlined': {
                                    color: '#fff !important',
                                },
                            }}
                            Menustyle={{
                                borderRadius: '9px',
                                borderBottom: '1px solid rgb(73, 69, 79)',
                                background: ' #FFF',
                                '& .MuiMenuItem-root': {
                                    color: '#5F46FF',
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    minwidth: '100%',
                                    borderRadius: '8px',
                                    paddingTop: '3px',
                                    borderBottom: '1px solid rgb(73, 69, 79)',
                                },
                            }}
                        />
                    </div>
                    {item !== 0 && <div style={{ width: '223px' }}>
                        <Select
                            value={filter?.timeline}
                            options={timeLines}
                            variant="outlined"
                            size='small'
                            multiple={false}
                            optionsType="default"
                            onChange={(e: any) => handleChangeItem(e, 'timeline')}
                            IconComponent={() => <DownArrowIcon fill="#fff" />}
                            sx={{
                                color: '#fff !important',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                borderRadius: '8px',
                                paddingTop: '3px',
                                width: '223px',
                                height: '37px',
                                border: 'none',
                                background: '#5F46FF !important',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    color: '#fff !important',

                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .MuiSelect-outlined': {
                                    color: '#fff !important',
                                },
                            }}
                            Menustyle={{
                                borderRadius: '4px',
                                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3),0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
                                background: '#FFF',
                                '& .MuiMenuItem-root': {
                                    color: '#5F46FF',
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    // minwidth: '100%',
                                    borderRadius: '8px',
                                    paddingTop: '3px',
                                    borderBottom: '1px solid rgb(73, 69, 79)',
                                },
                            }}
                        />
                    </div>}
                </div>
                <div style={{ marginTop: '24px', background: 'white' }}>
                    <div className={styles.heightContainer} >
                        {item === 0 && <div>
                            <div style={{ borderBottom: '1px solid rgb(95, 70, 234,0.5)', padding: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px', marginTop: '10px' }}>
                                    <div style={{ display: 'flex', gap: '18px', justifyContent: 'center', alignItems: 'center' }}>
                                        <h2 className={styles.bestSellingHeader}>Customer switch between</h2>
                                        <div style={{ width: '200px', display: 'flex', gap: '10px' }}>
                                            <Select
                                                value={'Brands'}
                                                options={[
                                                    { label: 'Brands', value: 'Brands' },
                                                ]}
                                                onChange={() => { }}
                                                variant="filled"
                                                size='small'
                                                multiple={false}
                                                optionsType="default"
                                                itemHeight={30}
                                                itemPaddingTop={4}
                                                itemWidth={15}
                                                sx={{
                                                    color: '#5F46FF',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '24px',
                                                    minwidth: '100%',
                                                    borderRadius: '8px',
                                                    paddingTop: '3px',
                                                    '& .MuiSelect-filled': {
                                                        border: '1px solid #5F46FF',
                                                        color: '#5F46FF',
                                                        backgroundColor: '#5F46FF',
                                                    },
                                                }}
                                                Menustyle={{
                                                    borderRadius: '9px',
                                                    border: '1px solid rgba(191, 191, 191, 0.25)',
                                                    background: ' #FFF',
                                                    '& .MuiMenuItem-root': {
                                                        color: '#5F46FF',
                                                        fontFamily: 'Inter',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        lineHeight: '24px',
                                                        minwidth: '100%',
                                                        borderRadius: '8px',
                                                        paddingTop: '3px',
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                        <StarIcon /> <ExportIcon /> <ShareIcon />
                                    </div>
                                </div>
                            </div>
                            <CustomerSwitch {...{ brands, filter, value: filter?.timeline, options: timeLines ,handleChangeItem, page, setPage}} />
                        </div>}
                        {item === 1 && <div>
                            <div style={{ borderBottom: '1px solid rgb(95, 70, 234,0.5)', padding: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px', marginTop: '10px' }}>
                                    <div style={{ display: 'flex', gap: '18px', justifyContent: 'center', alignItems: 'center' }}>
                                        <h2 className={styles.loyalityHeader} style={{}}>Loyality between</h2>
                                        <div style={{ width: '200px', display: 'flex', gap: '10px' }}>
                                            <Select
                                                value={types}
                                                options={[
                                                    { label: "Brands", value: "brand_name" },
                                                    { label: "Brand Groups", value: "brand_group" },
                                                    { label: "Product Category", value: "product_category" },
                                                ]}
                                                variant="filled"
                                                size='small'
                                                onChange={(e: any) => handleChangeItem(e, 'category')}
                                                multiple={false}
                                                optionsType="default"
                                                itemHeight={30}
                                                itemPaddingTop={4}
                                                itemWidth={15}
                                                IconComponent={() => <DownArrowIcon fill="#5F46FF" />}
                                                sx={{
                                                    color: '#5F46FF',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '24px',
                                                    minwidth: '100%',
                                                    borderRadius: '8px',
                                                    paddingTop: '3px',
                                                    '& .MuiSelect-filled': {
                                                        border: '1px solid #5F46FF',
                                                        color: '#5F46FF',
                                                        backgroundColor: '#5F46FF',
                                                    },
                                                }}
                                                Menustyle={{
                                                    borderRadius: '9px',
                                                    border: '1px solid rgba(191, 191, 191, 0.25)',
                                                    background: ' #FFF',
                                                    '& .MuiMenuItem-root': {
                                                        color: '#5F46FF',
                                                        fontFamily: 'Inter',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        lineHeight: '24px',
                                                        minwidth: '100%',
                                                        borderRadius: '8px',
                                                        paddingTop: '3px',
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                        <StarIcon /> <ExportIcon /> <ShareIcon />
                                    </div>
                                </div>
                            </div>
                            <LoyalityCS {...{ brands, types, filter }} /> </div>}
                        {item === 2 && <div>
                            <div style={{ borderBottom: '1px solid rgb(95, 70, 234,0.5)', padding: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px', marginTop: '10px' }}>
                                    <div style={{ display: 'flex', gap: '18px', justifyContent: 'center', alignItems: 'center' }}>
                                        <h2 className={styles.loyalityHeader} style={{ width: '30px' }}>Overlap</h2>
                                    </div>
                                    <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                        <StarIcon /> <ExportIcon /> <ShareIcon />
                                    </div>
                                </div>
                            </div><Overlap {...{ brands, types, filter }}/></div>}
                        {item === 3 && <div>
                            <div style={{ borderBottom: '1px solid rgb(95, 70, 234,0.5)', padding: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px', marginTop: '10px' }}>
                                    <div style={{ display: 'flex', gap: '18px', justifyContent: 'center', alignItems: 'center' }}>
                                        <h2 className={styles.loyalityHeader} style={{ width: '30px' }}>Promotions</h2>
                                    </div>
                                    <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                        <StarIcon /> <ExportIcon /> <ShareIcon />
                                    </div>
                                </div>
                            </div> <Promotions brands={promoBrands} skus={skus} filter={filter} setPage={setPromoPage} page={promoPage} /> </div>}

                    </div>
                </div>
            </div>
        </>
    )
}