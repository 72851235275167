function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const handleNavigate = (navigate, path) => {
  const token = localStorage.getItem('access_token');
  if (!token) {
    navigate(path);
  } else {
    navigate('/home')
  }
}

export { makeid }