import React from "react";
import styles from "../../styles.module.scss";
import { ReactComponent as BackArrowIcon } from "../../../../../assets/svg/backarr.svg";
import { ReactComponent as TickIcon } from "../../../../../assets/svg/tick.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/svg/save.svg";
import crossimg from "../../../../../assets/svg/cross.png";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SelectInput from "../inputs/SelectInput";
import DateInput from "../inputs/DateInput";
import TextInput from "../inputs/TextInput";
import RadioInput from "../inputs/RadioInput";

const CreateForm = ({ handleBack }: any) => {
  return (
    <div
      style={{
        background: "white",
        borderRadius: "5px",
        marginTop: "8px",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid rgb(95, 70, 234,0.5)",
          borderRadius: "5px 5px 0px 0px",
          height: "63px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "18px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BackArrowIcon style={{ cursor: "pointer" }} onClick={handleBack} />
          <h2
            style={{
              color: "rgb(19, 15, 53)",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.5px",
            }}
          >
            Create Camapign
          </h2>
        </div>
      </div>
      <div>
        <div className={styles["form"]}>
          <div className={styles["left-form"]}>
            <SelectInput
              {...{
                label: "Type",
              }}
            />
            <SelectInput
              {...{
                label: "Name",
              }}
            />
            <div
              style={{
                display: "flex",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <DateInput
                {...{
                  label: "Duration",
                }}
              />
              <DateInput />
            </div>
            <TextInput
              {...{
                label: "Daily Budget",
              }}
            />
            <div
              style={{
                display: "flex",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <SelectInput
                {...{
                  label: "Category",
                }}
              />
              <SelectInput {...{}} />
            </div>
            <div
              style={{
                display: "flex",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <SelectInput
                {...{
                  label: "Product",
                }}
              />
              <SelectInput {...{}} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <p className={styles["form-label"]}>Target:</p>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="automatic_targeting"
                      control={<Radio />}
                      label="Automatic targeting"
                    />
                    <FormControlLabel
                      value="manual_targeting"
                      control={<Radio />}
                      label="Manual targeting"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={styles["rule-content"]}>
            <p className={styles["title"]}>Rule book</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <p className={styles["mini-title"]}>How to select:</p>
              <p className={styles["content"]}>
                Selecting categories, brands, duration and budgets are crucial
                for a successful media campaign. Ensure you understand your
                target audience and market to make informed decisions.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <p className={styles["mini-title"]}>Tips:</p>
              <ul className={styles["content-list"]}>
                <li>Selecting categories, brands, duration</li>
                <li>Selecting categories, brands, duration</li>
                <li>Selecting categories, brands, duration</li>
                <li>Selecting categories, brands, duration</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <p className={styles["mini-title"]}>Aditional Information:</p>
              <p className={styles["content"]}>
                Selecting categories, brands, duration and budgets are crucial
                for a successful media campaign.
              </p>
            </div>
          </div>
        </div>
        <div className={styles["footer-buttons"]}>
          <Button
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 55px 10px 55px",
              color: "rgb(247, 247, 247)",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.1px",
              textAlign: "center",
              borderRadius: "100px",
              boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.15)",
              background: "rgb(19, 15, 53)",
              textTransform: "none",
              "&:hover": {
                background: "rgb(19, 15, 53)",
              },
            }}
          >
            <TickIcon fill="white" />
            Create
          </Button>
          <Button
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 24px 10px 24px",
              color: "rgb(19, 15, 53)",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.1px",
              textAlign: "center",
              borderRadius: "100px",
              boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.15)",
              border: "1px solid rgb(19, 15, 53)",
              textTransform: "none",
              "&:hover": {
                background: "inherit",
              },
            }}
          >
            <SaveIcon fill="#130F35" />
            Save as draft
          </Button>
          <Button
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              boxShadow: "none",
              background: "none",
              color: "rgb(19, 15, 53)",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.1px",
              textAlign: "center",
              textTransform: "none",
              "&:hover": {
                background: "inherit",
              },
            }}
          >
            <img src={crossimg} alt="img"/>
            {/* <CrossIcon fill="#130F35" /> */}
            Discard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateForm;
