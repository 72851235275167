import { useEffect, useState } from 'react';
import { BarchartLoyality } from './barchart';
import Select from '../../../../../../components/atoms/Select';
import { menustyles, selectStyles } from '../customer-switch';
import { getLoyalityBrandData, getLoyalityData } from '../../../../../../services/insights';
import Loader from '../../../../../../components/atoms/loader';
import { useSelector } from 'react-redux';
import InfiniteSelect from '../../../elements/InfiniteSelect';

export const LoyalityCS = ({ filter, types }: any) => {
    const [brands, setBrands] = useState<any>([])
    const [brand, setBrand] = useState(brands[0]?.label)
    const [loader, setLoader] = useState(false)
    const [finalData, setFinalData] = useState<any>([]);
    const [page, setPage] = useState(0);

    const handleChange = (e: any) => {
        setBrand(e)
    }

    const delivery = useSelector((state: any) => state?.sidebarContext?.delivery);
    const location = useSelector((state: any) => state?.sidebarContext?.location);
    const age_group = useSelector((state: any) => state?.sidebarContext?.age_group);
    const products = useSelector((state: any) => state?.sidebarContext?.products);

    const getData = async ({ brand, filter, based_on }: any) => {
        setLoader(true)
        const data = await getLoyalityData({ based_on: based_on, brand: brand, timeline: filter.timeline, products, location, age_group, delivery })
        setLoader(false)
        return data
    }

    const getBrandsData = async () => {
        const data: any = await getLoyalityBrandData('', page)
        const options = data?.results.map((time: string) => {
            return {
                label: time,
                value: time
            }
        })
        // setBrand(data?.results[0])
        setBrands([...brands, ...options])
    }
    useEffect(() => {
        (async () => {
            if (brand || brands[0]?.label) {
                const data: any = await getData({ brand: brand || brands[0]?.label, filter, based_on: types })
                let data1 = data?.total
                const getData1 = (name: any, data1: any) => {
                    const data = data1.filter((item: any) => {
                        if (item.segment === name) {
                            return item
                        }
                    })
                    return data.length > 0 ? data[0] : { sales: 0 }
                }
                const finData = [getData1("shoppers", data1)?.sales, getData1("new_shoppers", data1)?.sales, getData1("activated_shoppers", data1)?.sales, getData1("retained_shoppers", data1)?.sales, getData1("lapsing_shoppers", data1)?.sales, getData1("repeating_shoppers", data1)?.sales]
                setFinalData(finData)
            }
        })();
    }, [brand, filter, location, age_group, products, age_group, delivery, brands[0]?.label])

    useEffect(() => {
        getBrandsData()
    }, [page])

    const handleNextData = () => {
        setPage(page + 1)
    }


    return (
        <>
            <div>
                <div style={{ padding: '16px' }}>
                    <p style={{ fontSize: '14px', fontWeight: 400 }}>Find out how many customers were acquired, lost or remained loyal to a brand between periods.</p>
                </div>
                <div style={{ marginTop: '16px', gap: '10px', display: 'flex', flexDirection: 'column', paddingLeft: '16px' }}>
                    <h2 className={''} style={{ fontSize: '14px', fontWeight: 500 }}>Brand :</h2>
                    {/* <Select
                        value={brand}
                        options={brands}
                        onChange={(e: any) => handleChange(e)}
                        variant="outlined"
                        size='small'
                        multiple={false}

                        optionsType="default"
                        sx={{
                            ...selectStyles
                        }}
                        Menustyle={{
                            ...menustyles
                        }}
                    /> */}
                    <InfiniteSelect 
                        handleNextData={handleNextData}
                        data={brands}
                        label={brand || brands[0]?.label}
                        page={page}
                        setPage={setPage}
                        handleMenuItemClick={handleChange}
                    />
                </div>
                <div style={{ padding: '25px', marginBottom: '100px', marginTop: '25px' }}>
                    {!loader ? <BarchartLoyality {...{ data: finalData }} /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </div>}
                </div>
            </div></>
    )
}