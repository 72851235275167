import { Node, Input, Output } from "@nodl/core";
import { Observable } from "rxjs";

import { DataTempSchema } from "../Schema/DataTemp";

class Join extends Node {
  name = "Join";

  inputs = {
    left: new Input({ name: "left", type: DataTempSchema, defaultValue: {} }),
    right: new Input({ name: "right", type: DataTempSchema, defaultValue: {} }),
  };
  uid = "operation-join";
  isReady = false;

  outputs = {
    data: new Output({
      name: "output",
      type: DataTempSchema,
      observable: new Observable((subscriber) => {
        subscriber.next({ columns: [] });
      }),
    }),
  };

  leftJoin = ""
  rightJoin = ""

  setLeftJoin(val: any){
    this.leftJoin = val
    this.outputs.data.next({columns: [...this.getLeftColumns(), ...this.getRightColumns().filter((val_: any)=>{
      if(this.rightJoin){
        return val_.name != this.rightJoin
      }
      return true
    })]})
  }

  setRightJoin(val: any){
    this.rightJoin = val
    this.outputs.data.next({columns: [...this.getLeftColumns(), ...this.getRightColumns().filter((val_: any) => {
        return val_.name != val
    })]})
  }

  getLeftJoin() {
    return this.leftJoin
  }

  getRightJoin(){
    return this.rightJoin
  }

  getLeftColumns() {
    const val : any = this.inputs.left.value
    return val?.columns || [];
  }

  getRightColumns() {
    const val : any = this.inputs.right.value
    return val?.columns || [];
  }

  constructor() {
    super();
  }
}

export { Join };
