import React, { useEffect, useState } from "react";
import units from '../../../../../../../assets/svg/units.svg';
import sales from '../../../../../../../assets/svg/sales.svg';
import * as d3 from "d3";
const venn = require('venn.js');
export const VennDiagram = (props: any) => {
  const data = props.data[0]
  const [sets, setSets] = useState([
    { sets: ["A"], size: data?.customer_b1?.count || 0, label: `${props?.brandsData[0]?.name} (${data?.customer_b1?.count})` },
    { sets: ["B"], size: data?.customer_b2?.count || 0, label: `${props?.brandsData[1]?.name} (${data?.customer_b2?.count})` },
    { sets: ["A", "B"], size: data?.customer_in_both?.count || 0, label: `Both (${data?.customer_in_both?.count})` },
  ]);
  useEffect(() => {

    var chart = venn.VennDiagram()
      .wrap(false)
      .fontSize("16px")
      .width(540)
      .height(540);

    d3.select("#venn").datum(sets).call(chart);
  }, [sets]);
  const list = [
    {
      name: props?.brandsData[0]?.name, color: 'rgb(133, 216, 221)'
    },
    {
      name: props?.brandsData[1]?.name, color: 'rgb(255, 182, 141)'
    }
  ]
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {data !== undefined && Object?.keys(data)?.length > 0 && <div style={{ display: 'flex', gap: '24px', justifyContent: 'center', alignItems: 'center', marginTop: '23px' }}>
          <div style={{ display: 'flex', gap: '12px' }}>
            {list.map((item) => {
              return (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <div style={{
                    background: item.color,
                    width: 24,
                    height: 24,
                    borderRadius: '50%'
                  }}>

                  </div>
                  <div>{item.name}</div>
                </div>
              )
            })}
          </div>
          {/* <div style={{ display: 'flex',gap: '12px',borderLeft:'1px solid grey',paddingLeft:'20px' }}>
          {list2.map((item) => {
            return (
              <div style={{ display: 'flex', gap: '12px' }}>
            
                <div>{item.name}</div>
                <img src={item.img} style={{
                  width: 20,
                  height: 20,
                }} alt="n"/>

              </div>
            )
          })}
        </div> */}
        </div>}
        <div id="venn" style={{ textAlign: "center" }}>
        </div>
      </div>
    </>
  );
};