import GroupedBarChart from "../../../../../../../components/molecules/bar-chart";

export const BarchartLoyality = ({data}:any) => {

    const getData = ({data}:any) => {
        const labels: any = [['Shoppers'], ['New shoppers'], ['Activated Shoppers'], ['Retained shoppers'], ['Lapsing shoppers'], ['Repeating shoppers']]
        const datasets: any = [
            {
                label: 'dataset1',
                data: data,
                backgroundColor: [
                    'rgb(139, 151, 255)',
                    'rgb(139, 151, 255)',
                    'rgb(116, 194, 251)',
                    'rgb(255, 182, 141)',
                    'rgb(255, 141, 148)',
                    'rgb(255, 133, 214);',
                ],
                borderColor: [
                    'rgb(139, 151, 255)',
                    'rgb(139, 151, 255)',
                    'rgb(116, 194, 251)',
                    'rgb(255, 182, 141)',
                    'rgb(255, 141, 148)',
                    'rgb(255, 133, 214);',
                ],
                barPercentage: 0.4,
            },
            // {
            //     label: 'Churners to competition',
            //     data: [],
            //     backgroundColor: '#427ae3',
            //     borderColor: '#427ae3',

            // },
            // {
            //     label: 'Churners out of market',
            //     data: [],
            //     backgroundColor: '#3dc7d1',
            //     borderColor: '#3dc7d1',
            // }
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

    const toptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e'],
        fill: {
            colors: ["#1b3c7a", "#427ae3", "#3dc7d1", '#faa93e']
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: true,
                color: "black",
                formatter: function (context: any) {
                    if(typeof context ==='number'){
                        return context;
                    } else {
                        return context[1]-context[0]
                    }
                },
                anchor: "end",
                offset: -15,
                align: "start",

            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '96px',
                endingShape: 'rounded',
                barPercentage: 0.5,
                categorySpacing: 0,
            },
        },
        dataLabels: {
            enabled: false,

        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min:0,
                ticks: {
                    // stepSize: 100000// <----- This prop sets the stepSize
                }
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                grid: {
                    display: false,
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if(typeof context ==='number'){
                        return context;
                    } else {
                        return context[1]-context[0]
                    }
                }
            }
        },
    };
    return (
        <div style={{ height: '450px' }}>
            <GroupedBarChart {...{
                toptions,
                data: getData({data}),
                width: '1400%',
                height: 400
            }} />
        </div>
    )
}