import { ReactComponent as PlusIcon } from "../../../assets/svg/btnplus.svg";
import styles from "../../../layout/sidebarLayout.module.scss";
import insights from "../../../assets/svg/insights.svg";
import { ReactComponent as LeftArrow } from "../../../assets/svg/leftArrow.svg";
import { useLocation } from "react-router-dom";
import { SearchInput } from "../../../components/atoms/search";
import { Grid } from "@mui/material";
import MultipleSelectChip from "../../../components/molecules/select";
import { ReactComponent as SavedIcon } from "../../../assets/svg/saved.svg";
import { ReactComponent as Filter } from "../../../assets/svg/filter.svg";

export const Chip = ({ item, selected }: any) => {
  return (
    <div
      style={{
        height: "32px",
        padding: "6px 12px",
        background: selected ? "rgba(93, 66, 255, 0.08)" : "rgb(243, 243, 248)",
        color: selected ? "rgb(100, 77, 237)" : "rgb(128, 129, 146)",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        borderRadius: "6px",
        width: "fit-content",
      }}
    >
      {item}
    </div>
  );
};
export const SideLayout = () => {
  const sidebarData = [
    {
      label: "Subscriber",
      children: [
        {
          label: "Unilever",
        },
        {
          label: "P&G",
        },
        {
          label: "Loreal",
        },
        { label: "Pepsico" },
        { label: "Kelloges" },
        { label: "Haldiram" },
      ],
    },
    {
      label: "Categories being monetize",
      children: [
        {
          label: "Food and Beverage",
        },
        {
          label: "Beauty and Personal Care",
        },
      ],
    },
    {
      label: "Categories opportunity",
      children: [
        {
          label: "Electronics",
        },
        {
          label: "Sports and Outdoors",
        },
        {
          label: "Home and Garden",
        },
        { label: "Health and Wellness" },
      ],
    },
    {
      label: "Subscribers opportunity",
      children: [
        {
          label: "Nestle",
        },
        {
          label: "Balaji",
        },
        {
          label: "Hershey's",
        },
        { label: "M&M" },
      ],
    },
    /* {
            label: 'Saved Collection',
            children: [
                {
                    label: 'Unilever'
                },
                {
                    label: 'P&G'
                },
                {
                    label: 'Loreal'
                },
                { label: 'Pepsico' },
                { label: 'Kelloges' },
            ]
        }*/
  ];
  const location = useLocation();
  return (
    <>
      <div className={`sidebar`}>
        <ul className="h-full">
          <ul className="sidebarRightSideContainer h-full">
            <div className={styles.scrollContainer2 + 'h-full'}>
              {sidebarData.map((item) => {
                return (
                  <div>
                    <div className={styles.MainTextHeader}>{item.label}</div>
                    <Grid container style={{ padding: "16px" }}>
                      {item.children.map((item, index) => {
                        return (
                          <Grid item padding={"5px"}>
                            <Chip
                              {...{
                                item: item.label,
                                selected: [100].includes(index),
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                );
              })}
            </div>
          </ul>
        </ul>{" "}
      </div>
    </>
  );
};
