import { Grid } from "@mui/material"
import { ReactComponent as XylicIcon } from '../../assets/svg/xylicIcon.svg'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Profile from "../../assets/images/profile.jpg";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import { SecondAnswerTable } from "./table";
import { ThumbDown, DownloadForOffline, ContentCopy } from '@mui/icons-material';
export const Chatbot = () => {

    const [search, setSearch] = useState('')
    const [question, setQuestion] = useState<any>([
    ])
    const handleSend = () => {
        if (question.length === 0) {
            setQuestion([...question, {
                question: search,
                answer: 'Hi James! How can I help you today?'
            }])
        } else {
            setQuestion([...question, {
                question: search,
                answer: 'Sure, here is the list of all the media campaigns you created on 26/05/2023.'
            }])
        }
        setSearch('')
    }
    return (
        <Grid padding={"10px"}>
            <Grid sx={{
                background: '#FFF',
                height: '90vh'
            }}>

                <Grid sx={{
                    padding: '20px 10px 10px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px'
                }}>
                    {question.map((item: any, index: any) => {
                        return (
                            <Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                    <img src={Profile} alt="profile" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                    <p style={{}}>{item.question}</p>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'start', marginTop: '30px' }}>
                                    <XylicIcon style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                    <Grid>
                                        <p style={{}}>{item.answer}</p>
                                        {index === 1 && <Grid mt={2} height={"400px"}>
                                            <SecondAnswerTable />
                                        </Grid>}
                                    </Grid>
                                </Grid>

                            </Grid>
                        )
                    })}
                    {question.length > 0 && <Grid sx={{ display: 'flex', gap: '10px', marginLeft: '30px' }}><ContentCopy /><ThumbDown /> <DownloadForOffline /></Grid>
                    }                </Grid>

                {question.length === 0 && <Grid sx={{ position: 'fixed', top: '50%', left: '55%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <XylicIcon width={40} height={40} />
                    <p style={{ fontWeight: 600, fontSize: '16px', marginLeft: '4px', marginTop: '4px' }}>Xylix AI</p>
                </Grid>}

                <Grid sx={{
                    position: 'fixed', bottom: '2%',
                    display: 'flex', justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '30px',
                    alignItems: 'center',
                    width: '88%'
                }}>
                    {question.length === 0 && <Grid sx={{
                        display: 'flex', gap: '20px'
                    }}>
                        <Grid sx={{
                            padding: '10px',
                            border: '1px solid rgb(200, 197, 208)',
                            borderRadius: '4px',
                            width: '170px'
                        }}>
                            <p style={{ fontSize: '12px' }}>Category 1</p>
                            <p style={{ fontSize: '16px', color: 'rgb(94, 92, 113)', fontWeight: 600 }}>Suggestion 1</p>
                        </Grid>
                        <Grid sx={{
                            padding: '10px',
                            border: ' 1px solid rgb(200, 197, 208)',
                            borderRadius: '4px',
                            width: '170px'
                        }}>
                            <p style={{ fontSize: '12px', color: 'rgb(142, 139, 157);' }}>Category 2</p>
                            <p style={{ fontSize: '16px', color: 'rgb(94, 92, 113)', fontWeight: 600 }}>Suggestion 2</p>
                        </Grid>  <Grid sx={{
                            padding: '10px',
                            border: ' 1px solid rgb(200, 197, 208)',
                            borderRadius: '4px',
                            width: '170px'
                        }}>
                            <p style={{ fontSize: '12px' }}>Category 3</p>
                            <p style={{ fontSize: '16px', color: 'rgb(94, 92, 113)', fontWeight: 600 }}>Suggestion 3</p>
                        </Grid>
                    </Grid>}
                    <TextField
                        onChange={(e) => setSearch(e.target.value)}
                        variant="outlined"
                        value={search}
                        sx={{
                            width: '600px',
                            '& .MuiOutlinedInput-root:hover fieldset': {
                                borderColor: 'rgb(69, 69, 69)',
                            },
                            '& .MuiOutlinedInput-root.Mui-focused fieldset': {
                                outline: 'none',
                                boxShadow: 'none',
                                border: search ? '1px solid rgb(48, 36, 139)' : '1px solid rgb(69, 69, 69)',
                            },
                            '& .MuiOutlinedInput-root': {
                                paddingRight: '10px',
                            },
                        }}
                        placeholder="Type here to ask Xylic AI............."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SendIcon sx={{ color: search ? "rgb(91, 71, 245)" : 'rgb(142, 139, 157)', cursor: 'pointer' }} onClick={() => handleSend()} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

            </Grid>
        </Grid>
    )
}
