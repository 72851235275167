import { ReactComponent as StarIcon } from '../../../../../assets/svg/start.svg';
import { ReactComponent as ExportIcon } from '../../../../../assets/svg/download.svg';
import { ReactComponent as ShareIcon } from '../../../../../assets/svg/share.svg';
import styles from '../customer-behaviour/styles.module.scss';
import { Grid } from '@mui/material';
import { ListCard, ListSegment } from './listCard';
import Select from '../../../../../components/atoms/Select';
import seggrid from '../../../../../assets/svg/seggrid.svg';
import edit from '../../../../../assets/svg/edit.svg';
import segStyles from './styles.module.scss'
import flowStart from '../../../../../assets/svg/flowStart.svg'
import arrow from '../../../../../assets/svg/lineArrow.svg'
import { useEffect, useState } from 'react';
import SubtabsHeader from '../../elements/Subtabs/SubtabsHeader';
import { useSelector } from 'react-redux';
import { getAutoStack, getSegmentBrands, getSegmentCategories, getSegmentProcessData, publishSegmentData } from '../../../../../services/insights';
const {
    reorder,
    reorderImmutable,
    reorderFromTo,
    reorderFromToImmutable
} = require("react-reorder");
const Reorder = require('react-reorder')
const move = require('lodash-move')

export const selectStyles = {
    color: '#fff !important',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    borderRadius: '8px',
    paddingTop: '3px',
    width: '100%',
    height: '36px',
    border: 'none',
    background: '#FFF !important',
    '& .MuiOutlinedInput-notchedOutline': {

        border: '1px solid rgb(94, 92, 113)',
        color: 'rgb(94, 92, 113)'

    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgb(94, 92, 113)',
    },
    '& .MuiSelect-outlined': {
        color: 'rgb(94, 92, 113)'
    },
}
export const menustyles = {
    borderRadius: '9px',
    border: '1px solid rgba(191, 191, 191, 0.25)',
    background: ' #FFF',
    '& .MuiMenuItem-root': {
        color: '#5F46FF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        minwidth: '100%',
        borderRadius: '8px',
        paddingTop: '3px',
    },
}
export const Segmentation = () => {

    const [data, setData] = useState([
        { name: 'Time Shifting', id: 1 },
        { name: 'Average Order Value', id: 2 },
        { name: 'Trips', id: 3 },
        { name: 'Loyality Check', id: 4 }
    ]);
    const [checked, setChecked] = useState<boolean>(false);
    const [flowData, setFlowData] = useState<any>([]);
    const [autoStack, setAutoStack] = useState({})
    const [processData, setProcessData] = useState<any>([''])
    const [brandsData, setBrandsData] = useState<any>([])
    const [manData, setManData] = useState<any>({})
    const processTabs = [
        { label: 'Select process', value: '' },
        { label: 'Time Shifting', value: 'timeshift' },
        // { label: 'Helper For Deography', value: 'helper_for_demography' },
        { label: 'Average order value', value: 'average_order_value' },
        { label: 'Trips', value: 'trips' },
        { label: 'Demographics', value: 'demography' },
        { label: 'Loyalty check', value: 'loyality_check' }
    ]

    const brands = [
        { label: 'Select', value: '' }
    ]

    const outputs = [
        { label: 'Select parameter', value: '' },
        { label: 'Customer', value: 'Customer' },
    ]

    const [formData, setFormData] = useState<any>({
        process: "",
        brand: "",
        output: ""
    });

    const [formData2, setFormData2] = useState<any>({});

    function array_move(arr: any, old_index: any, new_index: any) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.map((item: any, index: any) => {
            if (index === 0) {
                return {
                    ...item, id: (Math.random() * (40 - 20))
                }
            } else return item
        })
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return [...arr]; // for testing
    };

    const onReorder = (e: any, from: number, to: number) => {
        const findata = array_move(data, from, to)
        setData(findata);
    };

    const handleEnableCheck = (e: any) => {
        setChecked(e.target.checked);
    }

    const handleProcess = async (value: any) => {
        const data: any = await getSegmentProcessData({ path: value })
        if (data?.results?.length > 0) {
            const finData = data.results.map((item: any) => {
                return {
                    label: item,
                    value: item
                }
            })
            setProcessData(finData)
        }
        else {
            const finData: any = []
            if (value === 'demography') {
                Object.keys((data?.results)).map((item) => {
                    finData.push({
                        name: item,
                        values: data?.results[item]?.map((item: any) => {
                            return {
                                label: item,
                                value: item
                            }
                        })
                    })
                })
            } else if (['average_order_value', 'trips'].includes(value)) {
                Object.keys((data?.results)).map((item) => {
                    finData.push({
                        label: data.results[item],
                        value: data.results[item]
                    })
                })
            }
            setProcessData(finData)
        }
        setFormData({ ...formData, process: value })
    }

    const handlepublish = async () => {
        const response: any = await publishSegmentData({
            item: formData?.selectBrand, process: formData.process, parameter: formData.result ? formData.result : Object.values(formData2), users: 0
        })

        setFlowData([...flowData, {
            ...response,
            id: 1,
            num: response.CUSTOMERS,
            process:formData.process,
            output:'Customers'
        }]);
        setManData({
            ...response
        })
        setFormData({ ...formData, process: '', selectBrand: "", output: "",result:'' })
        setFormData2({})
    }

    const handleBrandType = async (value: any) => {
        const data: any = value !== 'category' ? await getSegmentBrands() : await getSegmentCategories()
        const finData = data?.results.map((item: any) => {
            return {
                label: item,
                value: item
            }
        })
        setBrandsData(finData)
    }

    const handleChangeFormData = (e: any, type: string, final = '') => {
        const value = e.target.value;
        switch (type) {
            case "process":
                handleProcess(value)
                break;
            case "brand":
                setFormData({ ...formData, selectBrand: value })
                break;
            case "brandType":
                handleBrandType(value)
                break;
            case final:
                setFormData({ ...formData, selectBrand: value })
                break;
            case 'result':
                setFormData({ ...formData, result: value })
                break;
            case "output":
                setFormData({ ...formData, output: value });
                break;
            default:
                break;
        }
    }
    const handleChangeFormData2 = (e: any, index: string, final = '') => {
        const value = e.target.value;
        const data={...formData2}
        data[index] = value
        setFormData2({...data});
    }


    const handleAddStack = () => {
        handlepublish()
    }


    const FlowList = ({ item, arrow2 = true }: any) => {
        const styles = {
            container: {
                background: '#FFF',
                padding: '16px',
                borderRadius: '5px',
                boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.05)',
                width: 'fit-content',
            },
            content: {
                display: 'flex',
                gap: '8px',
            },
            image: {
                width: '20px', // Adjust the width as needed
                height: '20px', // Adjust the height as needed
            },
            title: {
                fontSize: '14px',
                fontWeight: 400,
                margin: 0,
            },
            value: {
                fontSize: '14px',
                fontWeight: 500,
                marginTop: '8px',
                marginLeft: '20px',
            },
        };
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
                <div style={styles.container}>
                    <div style={styles.content}>
                        <img src={flowStart} alt='' style={styles.image} />
                        <h3 style={styles.title}>{item.output}</h3>
                    </div>
                    <h2 style={styles.value}>{item.num}</h2>
                </div>
                {arrow2 && <img src={arrow} alt='' />}
            </div>
        )
    }

    const category = useSelector((state: any) => state?.sidebarContext?.category);
    const delivery = useSelector((state: any) => state?.sidebarContext?.delivery);
    const location = useSelector((state: any) => state?.sidebarContext?.location);
    const age_group = useSelector((state: any) => state?.sidebarContext?.age_group);
    const products = useSelector((state: any) => state?.sidebarContext?.products);

    const autoStackdata = async () => {
        const response: any = await getAutoStack({ products, category })
        setAutoStack(response)
    }
    useEffect(() => {
        if (category?.length > 0) {
            autoStackdata()
        }
    }, [products, category, delivery, location, age_group])


    return (
        <>
            <SubtabsHeader />
            <div style={{ padding: '16px' }}>
                <div style={{ background: '#FFF' }}>
                    <div style={{ borderBottom: '1px solid rgb(95, 70, 234,0.5)', padding: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '6px', marginTop: '10px' }}>
                            <div style={{ display: 'flex', gap: '18px', justifyContent: 'center', alignItems: 'center' }}>
                                <h2 className={styles.loyalityHeader} style={{ width: '30px' }}>Segmentation</h2>
                                <div style={{ width: '200px', display: 'flex', gap: '10px' }}>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                <StarIcon /> <ExportIcon /> <ShareIcon />
                            </div>
                        </div>
                    </div>
                    <Grid container className={segStyles.scrollContainerseg}>
                        <Grid xl={9.5} lg={9} sx={{ padding: '16px', borderRight: '1px solid rgb(229, 225, 236)' }}>
                            <div>
                                <p style={{ fontSize: '14px', fontWeight: 400 }}>Find out how many customers were acquired, lost or remained loyal to a brand between periods.</p>
                                <ListCard {...{ autoStack }} />
                                <div style={{ display: 'flex', gap: '12px' }}>
                                    <input type="checkbox" checked={checked} onChange={handleEnableCheck} style={{ cursor: 'pointer' }} />
                                    <p style={{ fontSize: '14px', fontWeight: 500, color: 'rgb(91, 71, 245)' }}>Enable Manual Stack</p>
                                </div>
                                <div style={{ background: 'rgb(247, 247, 247)', minHeight: '400px', marginTop: '10px', borderRadius: '5px', padding: '12px' }} className={styles.heightContainer}>
                                  {Object.keys(manData).length>0 &&  <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <ListSegment {...{ item: { name: 'Manual Stack', aov: manData?.AOV, trips: manData?.TRIPS, customers: manData?.CUSTOMERS }, }} />
                                    </div>}
                                    <div style={{ marginTop: -120 }}>
                                        {flowData.map((item: any, index: number) => {
                                            return <FlowList {...{ item, arrow2: flowData?.length === index + 1 ? false : true }} />
                                        })}

                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid xl={2.5} lg={3} className='right-inputs' sx={{ pointerEvents: checked ? 'all' : 'none', opacity: checked ? 1 : 0.4 }}>
                            <Grid sx={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <p style={{ fontSize: '14px', fontWeight: 500 }}>Process</p>
                                <Select
                                    value={formData?.process}
                                    options={processTabs}
                                    variant="outlined"
                                    size='small'
                                    multiple={false}
                                    optionsType="default"
                                    onChange={(e: any) => handleChangeFormData(e, 'process')}
                                    sx={{
                                        ...selectStyles
                                    }}
                                    Menustyle={{
                                        ...menustyles
                                    }}
                                />
                                <div style={{ pointerEvents: formData?.process ? 'all' : 'none', opacity: formData?.process ? 1 : 0.4, display: "flex", gap: '10px', flexDirection: "column" }}>
                                    <p style={{ fontSize: '14px', fontWeight: 500 }}>Input</p>
                                    <Select
                                        value={formData?.brand || ''}
                                        options={[
                                            { label: 'Select', value: '' },
                                            { label: 'Brand', value: 'brand_name' },
                                            { label: 'Category', value: 'category' }
                                        ]}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'brandType')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />
                                    <Select
                                        value={formData?.selectBrand || ''}
                                        options={[...brands, ...brandsData]}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'brand')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />
                                    {typeof (processData[0]?.value) !== 'string' && typeof (processData[0]?.value) !== 'number' && formData.process !== '' ? <>
                                        {Object.keys(processData).map((item: any, index: any) => {
                                            return (
                                                <Select
                                                    value={formData2[index] || ''}
                                                    options={[...brands, ...processData[item]?.values]}
                                                    variant="outlined"
                                                    size='small'
                                                    multiple={false}
                                                    optionsType="default"
                                                    onChange={(e: any) => handleChangeFormData2(e, index)}
                                                    sx={{
                                                        ...selectStyles
                                                    }}
                                                    Menustyle={{
                                                        ...menustyles
                                                    }}
                                                />
                                            )
                                        })}
                                    </> : <Select
                                        value={formData?.result || ''}
                                        options={[...brands, ...processData]}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'result')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />}
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    pointerEvents: (formData?.selectBrand) ? 'all' : 'none',
                                    opacity: (formData?.selectBrand) ? 1 : 0.4,
                                }}>
                                    <p style={{ fontSize: '14px', fontWeight: 500 }}>Output</p>
                                    <Select
                                        value={formData?.output}
                                        options={outputs}
                                        variant="outlined"
                                        size='small'
                                        multiple={false}
                                        optionsType="default"
                                        onChange={(e: any) => handleChangeFormData(e, 'output')}
                                        sx={{
                                            ...selectStyles
                                        }}
                                        Menustyle={{
                                            ...menustyles
                                        }}
                                    />
                                </div>
                                <p style={{
                                    textDecoration: 'underline',
                                    color: 'rgb(95, 70, 254)',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'end',
                                    cursor: "pointer",
                                    pointerEvents: formData?.output ? 'all' : 'none',
                                    opacity: formData?.output ? 1 : 0.4,
                                }} onClick={handleAddStack}>+ Add</p>
                            </Grid>
                            <Grid mt={3} borderBottom={'1px solid rgb(229, 225, 236)'}>
                                <Reorder
                                    reorderId="my-list" // Unique ID that is used internally to track this list (required)
                                    reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                                    // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                                    component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                                    placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                                    draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                    lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                    holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                    touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                                    mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                                    onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
                                    autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                                    disabled={false} // Disable reordering (optional), defaults to false
                                    disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                                    placeholder={
                                        <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                                    }
                                >
                                    {flowData.map((item: any, index: number) => {
                                        return (
                                            <Grid borderTop={"1px solid rgb(229, 225, 236)"}>
                                                <Grid display={'flex'} justifyContent={"space-between"} padding={"14px"}>
                                                    <Grid display={'flex'} gap={'16px'}>
                                                        <img src={seggrid} alt='' />
                                                        <h2 style={{ fontSize: '12px', fontFamily: 'Poppins' }}>{item.process}</h2>
                                                    </Grid>
                                                    <img src={edit} alt='' />
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Reorder >
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}