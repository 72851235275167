import "./index.scoped.scss";
import { useCallback, useEffect } from "react";
import { SideLayout } from "./sideLayout";
import { CircuitStore, Circuit } from "@nodl/react";
import { Connector } from "./Nodes/connector";
import { Join } from "./Nodes/join";
import { Drop } from "./Nodes/drop";
import { Rename } from "./Nodes/rename";
import { TypeConvertor } from "./Nodes/typeConvertor";
import { RightSideLayout } from "./rightSideLayout";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { CsvInitDialog } from "./Components/CsvInitDialog";
import { TransactionOutput } from "./Nodes/out_transaction";
import { UserOutput } from "./Nodes/out_user";
import SubtabsHeader from "../SubtabsHeader";
import { onMainTabChange, onsubTabChange } from "../../../store/header/reducer";
import { Split } from "./Nodes/split";

const store = new CircuitStore();

export const DataAnimator = () => {
  const [nodeMenu, setNodeMenu] = useState("");
  const [dialogId, setDialoigId] = useState("");


  return (
    <div style={{ display: "flex" }}>
     
      <CsvInitDialog
        open={dialogId == "csv-init"}
        handleClose={() => setDialoigId("")}
        handleAdd={(name: string, columnNames: any) => {
          const conn = new Connector(name, columnNames);
          store.setNodes([[conn, { x: 0, y: 0 }]]);
       
          setDialoigId("")
        }}
      />
      <SideLayout
        onAdd={(id: string, x: number, y: number) => {
          switch (id) {
            case "data-connector":
              setDialoigId("csv-init");
              break;
            case "drop-column":
              const drop = new Drop();
              store.setNodes([[drop, { x, y }]]);
              break;
            case "join-tables":
              const join = new Join();
              store.setNodes([[join, { x, y }]]);
              break;
            case "rename-column":
              const rename = new Rename();
              store.setNodes([[rename, { x, y }]]);
              break;
            case "type-convert":
              const typeConvertor = new TypeConvertor();
              store.setNodes([[typeConvertor, { x, y }]]);
              break;
            case "operation-split":
                const splitNode = new Split()
                store.setNodes([[splitNode, { x, y }]]);
                break;
            case "output-transaction":
              const transactionOutput = new TransactionOutput();
              store.setNodes([[transactionOutput, { x, y }]]);
              break;
            case "output-user":
              const usersOutput = new UserOutput()
              store.setNodes([[usersOutput, { x, y }]]);
              break;
          }
        }}
      />
      <div className="absolute  w-full z-[100]" style={{left:260}}>
        <SubtabsHeader />
        </div>
      <section className={"home-section"}>
        <Circuit
          className="canvas"
          store={store}
          nodeWindowResolver={useCallback((node: any)=>{
            if(node instanceof Connector){
              return <div className="bg-[#242736] pt-2 px-2 text-white text-md font-bold">{node.nodeRedableName}</div>
            }
          }, [])}
          onSelectionChanged={(nodes) => {
            if (nodes.length == 1) {
              const node = nodes[0]
              setNodeMenu(node.id);
            } else {
              setNodeMenu("");
            }
          }}
          onNodeRemoval={() => {
            setNodeMenu("");
          }}
        />
      </section>
      {nodeMenu == "" ? <></> : <RightSideLayout  nodeId={nodeMenu} store={store}/>}
    </div>
  );
};
