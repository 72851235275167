import { useNavigate } from "react-router-dom";
import RightTabs from "../components/molecules/TabPanel";
import { useState } from "react";
import './navbar.scss';
import { useDispatch, useSelector } from "react-redux";
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import rightArrow from '../assets/svg/sidebar/rightArrow.svg'
import leftArrow from '../assets/svg/sidebar/leftArrow.svg'
import { setShowSidebar } from "../store/header/reducer";
export const Navbar = ({
    xylicLogo,
    settings,
    location,
    mainTab,
    handleType,
    emptyDashData,
    isDashboard,
    dash_tabdata,
    tabdata,
    showSide,
}: any) => {
    const { permissions, loading } = useSelector((state: any) => state?.headerContext);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { userName } = useSelector((state: any) => state?.headerContext);
    const [drawerState, setDrawerState] = useState<boolean>(false)
    const getHandleSidebar = () => {
        if (location.pathname === '/new-insights') {
            return true
        }
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };



    const MobileDrawerList = (
        <>
            <Box sx={{ width: 250 }} onClick={() => {
                setDrawerState(false)
            }}>
                <List>
                    <ListItem onClick={() => navigate('/home')} key={'Home'} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Home'} />
                        </ListItemButton>
                    </ListItem>
                    {emptyDashData.map((item: any, index: number) => {

                        const tabNameLower = item.name.toLowerCase();

                        // Handle specific cases where the permission key doesn't directly match the tab name
                        const permissionKey = tabNameLower === 'connections' ? 'connectors' : tabNameLower;
                        const disabled = permissions[permissionKey]

                        return (

                            <>
                                {disabled && <ListItem onClick={() => navigate(item.link)} key={item.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>}
                            </>
                        )
                    })}
                </List>

            </Box>
        </>
    );


    return (
        <div className="flex w-full nav-bar-mobile">
            <Drawer open={drawerState} onClose={() => setDrawerState(false)}>
                {MobileDrawerList}
            </Drawer>
            {!loading && <div className="logo-details cursor-pointer  max-sm:flex-1 hidden max-sm:flex max-sm:ml-5" onClick={() => setDrawerState(true)}>
                <MenuIcon />
            </div>}
            <div
                className="logo-details cursor-pointer max-sm:flex-1 max-sm:hidden "
                onClick={() => {
                    navigate('/home')
                }}
            >
                <i className="bx bxl-c-plus-plus" />

                <span className="logo_name max-sm:hidden">
                    <img src={xylicLogo} alt="logo" height={180} />
                </span>
            </div>
            {!getHandleSidebar() ? <div style={{ justifyContent: 'center', alignItems: 'center', marginLeft: '3%' }} className="flex max-sm:hidden">
                <RightTabs
                    {...{
                        tabs: (location.pathname === '/home' || location.pathname === '/connections')
                            ? emptyDashData
                            : (isDashboard && location.pathname !== '/xylic-ai')
                                ? dash_tabdata
                                : tabdata,
                        selectedTab: mainTab,
                        handleType: handleType,
                    }}
                />
            </div> : <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', cursor: 'pointer' }} onClick={()=>dispatch(setShowSidebar(!showSide))}>
                <img src={showSide ? rightArrow:leftArrow} alt="icon" />
            </div>}

            <div className="navbar_content flex-1">
                <div
                    style={{

                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: '#B3B3B3',
                        color: '#fff',
                        fontSize: '20px',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                    }}
                    className="profile flex max-sm:hidden"
                >
                    {localStorage.getItem('name')?.[0]?.toUpperCase() || 'U'}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h3 style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 600, lineHeight: '17px', color: 'rgb(64, 64, 64)' }}>
                        {userName || localStorage.getItem('name') || 'Unknown User'}
                    </h3>
                    <p style={{ fontSize: '12px', margin: '0px', fontFamily: 'Inter', fontWeight: 600, lineHeight: '15px', color: 'rgb(86, 86, 86)' }}>
                        Admin
                    </p>
                </div>
                <img onClick={toggleDropdown} src={settings} alt="" style={{ cursor: 'pointer' }} />
                {dropdownOpen && (
                    <div className="dropdown-menu">
                        <ul>
                            <li onClick={handleLogout}>Logout</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
