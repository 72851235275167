import CommonTable from "../../components/organisms/CommonTable";


const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
export const ActiveCol: any = ({ filter, page }: any) => {
    return (
        [
            {
                id: 'ad',
                columnLabel: 'AD',
                minWidth: 40,
                render: (row: any, index: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row.ad}
                    </p>
                ),
            },
            {
                id: 'product',
                columnLabel: 'Type',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row.product}
                    </p>
                ),
            },
            {
                id: 'sales',
                columnLabel: 'Budget',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style, }}>
                       {row.budget}
                    </p>
                ),
            },
            {
                id: 'createdOn',
                columnLabel: 'Created On',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row.createdOn}
                    </p>
                ),
            },
            {
                id: 'status',
                columnLabel: 'Status',
                minWidth: 40,
                render: (row: any) => (
                    <p color='#051433' onClick={() => { }} style={{ ...style }}>
                        {row.status}
                    </p>
                ),
            },
        ]
    )
}

export const rows = [
    { ad: '‘Welcome back’ 200 X 200 banner', product: 'Product', budget: 'INR 20,000 /-', createdOn: '26/05/2023', status: 'Active' },
    { ad: '‘Welcome back’ 200 X 200 banner', product: 'Product', budget: 'INR 20,000 /-', createdOn: '26/05/2023', status: 'Active' },
    { ad: '‘Welcome back’ 200 X 200 banner', product: 'Product', budget: 'INR 20,000 /-', createdOn: '26/05/2023', status: 'Active' },
    { ad: '‘Welcome back’ 200 X 200 banner', product: 'Product', budget: 'INR 20,000 /-', createdOn: '26/05/2023', status: 'Active' },
    { ad: '‘Welcome back’ 200 X 200 banner', product: 'Product', budget: 'INR 20,000 /-', createdOn: '26/05/2023', status: 'Active' },
    { ad: '‘Welcome back’ 200 X 200 banner', product: 'Product', budget: 'INR 20,000 /-', createdOn: '26/05/2023', status: 'Active' },
];

export const SecondAnswerTable = ({ data, handleRowClick, filter, loader, page }: any) => {
    return (
        <div>
            <CommonTable {...{
                cols: ActiveCol({ filter, page }),
                data: rows,
                tableData: rows,
                selection: false,
                onRowClick: handleRowClick,
                loading: loader
            }} />
        </div>
    )
}