import styles from './sidebarLayout.module.scss'
import { ReactComponent as LeftArrow } from '../assets/svg/leftArrow.svg'
import { useLocation, useNavigate } from "react-router-dom";
import MultipleSelectChip from "../components/molecules/select";
import { ReactComponent as SavedIcon } from '../assets/svg/saved.svg'
import { ReactComponent as Filter } from '../assets/svg/filter.svg'
import { useEffect, useState } from "react";
import { getCategoryData, getUtilsData } from "../services/insights";
import dashboard from '../assets/svg/sidebar/dashboard.svg'
import fav from '../assets/svg/sidebar/favourites.svg'
import ai from '../assets/svg/sidebar/xylicai.svg'
import archive from '../assets/svg/sidebar/archive.svg'
import plan from '../assets/svg/sidebar/plan.svg'
import creative from '../assets/svg/sidebar/creative.svg'
import launch from '../assets/svg/sidebar/lunh.svg'
import measure from '../assets/svg/sidebar/measure.svg'
import connections from '../assets/svg/sidebar/connections.svg'
import { useSelector } from 'react-redux';
export const Chip = ({ item, selected }: any) => {
    return (
        <div style={{
            height: '32px',
            padding: '6px 12px',
            background: selected ? 'rgba(93, 66, 255, 0.08)' : 'rgb(243, 243, 248)',
            color: selected ? 'rgb(100, 77, 237)' : 'rgb(128, 129, 146)',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            borderRadius: '6px',
            width: 'fit-content'
        }}>
            {item}
        </div>
    )
}

export const DashboardLayout = () => {
    const [state, setState] = useState({
        brand: [],
        category: [],
        delivery: [],
        products: [],
        location: [],
        age_group: []
    })
    const { showSide } = useSelector((state: any) => state?.headerContext)


    const navigate = useNavigate();

    const getCategory = async () => {
        const response: any = await getCategoryData();
        const data = response || []
        setState({
            ...state, category: data
        })
    }

    const getBrands = async () => {
        try {
            const response: any = await getUtilsData('brand_name/?limit=10');
            const data = response?.results || []
            setState({
                ...state, brand: data
            })
        } catch (error) {
            setState({
                ...state, brand: []
            })
        }
    }

    useEffect(() => {
        getCategory();
        getBrands();
        // getLocation();
    }, [])

    const CustomSidebarItem = ({ child }: any) => {
        const location = useLocation();
        const isActive = location.pathname === child?.path;

        return <div
            className={styles.childContainer}
            onClick={() => navigate(child?.path)}
            style={{
                // backgroundColor: isActive ? '#F8FAFC' : 'transparent',
                // padding: '8px 16px',
                // cursor: 'pointer',
                // borderRadius: '4px', // Optional, to make it look smoother
                // transition: 'background-color 0.3s ease', // Optional, smooth transition on hover
            }}
        >
            <div style={{
                display: 'flex', alignItems: 'center', gap: '4px', backgroundColor: isActive ? '#F8FAFC' : 'transparent',
                padding: '8px 8px',
                cursor: 'pointer',
                borderRadius: '4px', // Optional, to make it look smoother
                transition: 'background-color 0.3s ease'
            }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#F8FAFC';
                }}
                onMouseLeave={(e) => {
                    if (!isActive) e.currentTarget.style.backgroundColor = 'transparent';
                }}
            >
                <img src={child.icon} alt='' width={20} height={20} />
                <div style={{ fontFamily: 'Inter' }}>{child?.label}</div>
            </div>
        </div>

    }

    const sidebarData = [
        {
            label: 'Transaction',
            children: [
                // {
                //     label: 'Seller',
                //     options: ['Brand 1', 'Brand 2', 'Brand 3', 'Brand 4', 'Brand 5', 'Brand 6', 'Brand 7', 'Brand 8']
                // },
                {
                    label: 'Business Model',
                    disable: true
                },
                {
                    label: 'Merchant type',
                    options: ['Option 1', 'Option 2', 'Option 3'],
                    disable: true
                },
                { label: 'Delivery', path: 'delivery_type?limit=10', id: 'delivery' },
                { label: 'Day of week', disable: true },
                { label: 'Time of Day', disable: true },
                { label: 'Payment Method', disable: true }
            ]
        },
        {
            label: 'Products',
            children: [
                { label: 'Category', data: state.category, path: 'unique_values?column=product_category' },
                { label: 'Sub Category', disable: true },
                {
                    label: 'Brand Group',
                    path: 'brand_group'
                },
                {
                    label: 'Brand',
                    path: 'brand_name/?limit=10',
                    id: 'products',
                    data: state.brand
                },
                {
                    label: 'Item',
                    disable: true
                },
                {
                    label: 'Price Range',
                    disable: true
                },
                { label: 'Second-hand & Refurbished', disable: true },
            ]
        },
        {
            label: 'Customers',
            children: [
                {
                    label: 'Region & city',
                    path: 'location?limit=10',
                    id: 'location',
                    data: []
                },
                {
                    label: 'Age Range',
                    path: 'age_group?limit=10',
                    id: 'age_group'
                },
                {
                    label: 'Gender',
                    disable: true
                },
                { label: 'Customer Base', options: ['Option M', 'Option N', 'Option O'], disable: true },
            ]
        }
    ];

    const dashboardData = [
        {
            label: 'General',
            children: [
                {
                    label: 'Dashboard',
                    icon: dashboard,
                    path: '/insights'
                },
                {
                    label: 'Favourites',
                    icon: fav,
                    path: ''
                },
                {
                    label: 'Xylic Ai',
                    icon: ai,
                    path: '/xylic-ai'
                },
                {
                    label: 'Archive',
                    icon: archive,
                    path: ''
                },
            ]
        },
        {
            label: 'Services',
            children: [
                {
                    label: 'Plan',
                    icon: plan,
                    path: ''
                },
                {
                    label: 'Creatives & Content',
                    icon: creative,
                    path: ''
                },
                {
                    label: 'Launch Campaigns',
                    icon: launch,
                    path: ''
                },
                {
                    label: 'Measure',
                    icon: measure,
                    path: ''
                },
                {
                    label: 'Connections',
                    icon: connections,
                    path: '/connections'
                },
            ]
        }

    ]
    const location = useLocation()
    return (<>
        <div className={`sidebar`} style={{ background: '#F5F5F5',display:showSide ? 'none' :'' }}>
            {location.pathname.startsWith("/new-insights") && <ul className="sidebarRightSideContainer">
                {/* <div className="sidebarInsightsHeader">
                    <img src={insights} alt="" />
                    <p className="insightsHeader">Insights</p>
                </div> */}
                <div className={styles.scrollContainer} style={{ height: 'calc(100vh - 85px)' }}>
                    <div style={{ background: 'white', marginBottom: '20px',paddingBottom:'10px' }}>
                        {dashboardData.map((item) => {
                            return (
                                <div >
                                    <div className={styles.DashTextHeader} style={{ marginTop: '10px' }}>{item.label}</div>
                                    <div>
                                        {
                                            item.children.map((child: any) => {
                                                return (
                                                    <CustomSidebarItem child={child} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{ background: 'white' }}>
                    <div className={styles.DashTextHeader} style={{ marginTop: '0px',padding:'10px 0px 0px 26px',fontSize:'16px' }}>{"Filter"}</div>
                        {sidebarData.map((item) => {
                            return (
                                <div >
                                    <div className={styles.MainTextHeader} style={{ marginTop: '10px' }}>{item.label}</div>
                                    <div>
                                        {
                                            item.children.map((child: any) => {
                                                return (
                                                    <MultipleSelectChip
                                                        key={child.label}
                                                        label={child.label}
                                                        options={child.options || []}
                                                        disable={child.disable || false}
                                                        path={child?.path || ''}
                                                        data={child?.data || []}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ul>}
        </div>
    </>)
}